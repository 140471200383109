@import "https://static.cloud.coveo.com/atomic/v2/themes/coveo.css";
@import "https://fonts.googleapis.com/css?family=Material+Icons|Open+Sans:300,300i,400,400i,600,600i,700,700i";
@import "https://pro.fontawesome.com/releases/v5.15.1/css/all.css";

$header-top-bar-height: 44px;

@import "../shared/functions";
@import "../shared/variables";
@import "../shared/mixins";
@import "scss/fonts";
@import "scss/mixins";
@import "scss/root";
@import "scss/main";
@import "scss/header";
@import "scss/widgets";
@import "scss/components";
@import "scss/pages";
@import "scss/dark-mode";
