@mixin button-variant(
  $background,
  $border: $background,
  $color: color-contrast($background),
  $hover-background: if($color == $color-contrast-light, shade-color($background, 15%), tint-color($background, 15%)),
  $hover-border: $hover-background,
  $hover-color: color-contrast($hover-background),
  $active-background: if($color == $color-contrast-light, shade-color($background, 20%), tint-color($background, 20%)),
  $active-border: $active-background,
  $active-color: color-contrast($active-background),
  $disabled-background: $background,
  $disabled-border: $border,
  $disabled-color: color-contrast($disabled-background)
) {
  --eaw-btn-active-bg: #{$active-background};
  --eaw-btn-active-border-color: #{$active-border};
  --eaw-btn-active-color: #{$active-color};
  --eaw-btn-active-shadow: #{$btn-active-shadow};
  --eaw-btn-bg: #{$background};
  --eaw-btn-border-color: #{$border};
  --eaw-btn-color: #{$color};
  --eaw-btn-disabled-bg: #{$disabled-background};
  --eaw-btn-disabled-border-color: #{$disabled-border};
  --eaw-btn-disabled-color: #{$disabled-color};
  --eaw-btn-focus-shadow-rgb: #{rgb-values-from-hex(mix($color, $border, 15%))};
  // --eaw-btn-focus-shadow-color: #{rgb(mix($color, $border, 15%) / 50%)};
  --eaw-btn-hover-bg: #{$hover-background};
  --eaw-btn-hover-border-color: #{$hover-border};
  --eaw-btn-hover-color: #{$hover-color};
}

@mixin button-outline-variant(
  $color,
  $color-hover: color-contrast($color),
  $active-background: $color,
  $active-border: $color,
  $active-color: color-contrast($active-background)
) {
  --eaw-btn-active-bg: #{$active-background};
  --eaw-btn-active-border-color: #{$active-border};
  --eaw-btn-active-color: #{$active-color};
  --eaw-btn-active-shadow: #{$btn-active-shadow};
  --eaw-btn-bg: transparent;
  --eaw-btn-border-color: #{$color};
  --eaw-btn-color: #{$color};
  --eaw-btn-disabled-bg: transparent;
  --eaw-btn-disabled-border-color: #{$color};
  --eaw-btn-disabled-color: #{$color};
  --eaw-btn-focus-shadow-rgb: #{to-rgb($color)};
  --eaw-btn-hover-bg: #{$active-background};
  --eaw-btn-hover-border-color: #{$active-border};
  --eaw-btn-hover-color: #{$color-hover};
}

@mixin eaw-button() {
  background-color: var(--eaw-btn-bg);
  border: var(--eaw-btn-border-width) solid var(--eaw-btn-border-color);
  border-radius: var(--eaw-btn-border-radius);
  box-sizing: border-box;
  color: var(--eaw-btn-color);
  cursor: pointer;
  display: inline-block;
  font-size: var(--eaw-btn-font-size);
  font-weight: var(--eaw-btn-font-weight);
  letter-spacing: var(--eaw-btn-letter-spacing);
  line-height: var(--eaw-btn-line-height);
  min-width: 80px;
  padding: var(--eaw-btn-padding-y) var(--eaw-btn-padding-x);
  text-align: center;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  user-select: none;
  vertical-align: middle;

  &.eaw-btn--flat {
    background-color: transparent;
    border-color: transparent;
    color: var(--eaw-btn-bg);

    &:hover {
      color: var(--eaw-btn-hover-color);
    }
  }

  &:hover {
    --eaw-link-color-hover: var(--eaw-btn-hover-color);
    background-color: var(--eaw-btn-hover-bg);
    border-color: var(--eaw-btn-hover-border-color);
    color: var(--eaw-btn-hover-color);
    text-decoration: none;
  }

  &:focus-visible {
    --eaw-link-color-hover: var(--eaw-btn-hover-color);
    background-color: var(--eaw-btn-hover-bg);
    border-color: var(--eaw-btn-hover-border-color);
    box-shadow: var(--eaw-btn-focus-shadow);
    color: var(--eaw-btn-hover-color);
    outline: 0;
  }

  &:active,
  &.eaw-btn--active {
    --eaw-link-color-hover: var(--eaw-btn-active-color);
    background-color: var(--eaw-btn-active-bg);
    border-color: var(--eaw-btn-active-border-color);
    color: var(--eaw-btn-active-color);
    text-decoration: none;

    &:focus-visible {
      --eaw-link-color-hover: var(--eaw-btn-hover-color);
    }
  }

  &.disabled,
  &:disabled {
    background-color: var(--eaw-btn-disabled-bg);
    border-color: var(--eaw-btn-disabled-border-color);
    color: var(--eaw-btn-disabled-color);
    cursor: default;
    opacity: var(--eaw-btn-disabled-opacity);
    pointer-events: none;
  }

  &.eaw-btn--block {
    display: block;
    width: 100%;

    + .eaw-btn--block {
      margin-top: 1rem;
    }
  }

  @each $color, $value in $button-colors {
    &.eaw-btn--#{$color} {
      @if $color == "light" {
        @include button-variant(
          $value,
          $value,
          $hover-background: shade-color($value, 15%),
          $hover-border: shade-color($value, 20%),
          $active-background: shade-color($value, 20%),
          $active-border: shade-color($value, 25%)
        );
      } @else if $color == "dark" {
        @include button-variant(
          $value,
          $value,
          $hover-background: tint-color($value, 15%),
          $hover-border: tint-color($value, 10%),
          $active-background: tint-color($value, 20%),
          $active-border: tint-color($value, 10%)
        );
      } @else {
        @include button-variant($value, $value);
      }
    }
  }

  &.eaw-btn--outline {
    @include button-outline-variant(map-get($button-colors, 'primary'));

    @each $color, $value in $button-colors {
      &.eaw-btn--#{$color} {
        @include button-outline-variant($value);
      }
    }
  }

  &.eaw-btn--lg {
    --eaw-btn-padding-y: 1rem;
    --eaw-btn-padding-x: 1rem;
    --eaw-btn-font-size: 1rem;
    min-width: 360px;

    @media screen and (max-width: 480px) {
      min-width: 100%;
    }
  }

  // &.eaw-btn--download {
  //   &:before {
  //     @include fa-icon-light($fa-var-arrow-circle-down, 'inline-block');
  //     margin-right: 5px;
  //   }
  // }
}


@mixin eaw-right-arrow() {
  padding-right: 20px;
  position: relative;

  &:hover {
    &:after {
      text-decoration: none;
      right: -4px;
    }
  }

  &:after {
    @include fa-icon-light('arrow-right', 'absolute');
    right: 0;
    transition: right 250ms;
  }
}
