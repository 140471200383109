html[data-eaw-theme=dark] {
  --eaw-body-color: var(--eaw-body-color-light);
  --eaw-body-color-rgb: var(--eaw-body-color-light-rgb);
  --eaw-body-bg: var(--eab-gray-a-700);
  --eaw-body-bg-secondary: var(--eab-gray-a-600);
  --eaw-body-bg-tertiary: var(--eab-gray-a);
  --eaw-border-color: rgb(255 255 255 / 15%);
  --eaw-card-bg: var(--eaw-body-bg-tertiary);
  --eaw-card-border-color: var(--eab-gray-b-700);
  --eaw-card-gray-bg: var(--eab-gray-a-400);
  --eaw-card-gray-border-color: var(--eab-gray-a-300);
  --eaw-card-shadow-color: rgb(255 255 255 / 5%);
  --eaw-dropdown-shadow: 0px 4px 8px rgb(255 255 255 / 5%);
  --eaw-link-color: #85A2FA;
  --eaw-link-color-hover: var(--eab-blue-300);
  --eaw-link-color-rgb: #{to-rgb(#85A2FA)};
  --eaw-card-link-color: #{$link-color-dark-mode};
  --eaw-card-link-color-rgb: #{rgb-values-from-hex($link-color-dark-mode, '')};
  --eaw-card-link-color-hover: #{$link-color-dark-mode-hover};
  --eaw-header-background: linear-gradient(to right, var(--eab-blue-900) 0%, var(--eab-blue-700) 50%, var(--eab-blue-900) 100%);
  --eaw-headings-color: var(--eab-gray-e);
  // --eaw-widget-table-bg: var(--eaw-body-bg);
  --eaw-widget-table-alt-bg: rgb(255 255 255 / 5%);
  --eaw-widget-table-head-bg: rgb(var(--eaw-widget-table-head-bg-rgb) / 50%);
  --eaw-widget-table-head-color: var(--eab-gray-e);
  --eaw-widget-table-border-color: var(--eab-gray-c);
  --eaw-lumapps-divider-color: rgb(255 255 255 / 25%);
  --eaw-calendar-event-color: #{$calendar-event-color-dark};
  --eaw-calendar-event-color-rgb: #{to-rgb($calendar-event-color-dark)};
  --eaw-calendar-event-company-color: #{$calendar-event-company-color-dark};
  --eaw-calendar-event-company-color-rgb: #{to-rgb($calendar-event-company-color-dark)};
  --eaw-calendar-event-holiday-color: #{$calendar-event-holiday-color-dark};
  --eaw-calendar-event-holiday-color-rgb: #{to-rgb($calendar-event-holiday-color-dark)};
  --eaw-calendar-event-location-color: #{$calendar-event-location-color-dark};
  --eaw-calendar-event-location-color-rgb: #{to-rgb($calendar-event-location-color-dark)};
  --eaw-notification-bg: var(--eab-gray-b);
  --eaw-modal-shadow-color: rgb(255 255 255 / 5%);
  --eaw-popover-shadow-color: rgb(255 255 255 / 5%);

  color-scheme: dark;

  eaw-calendar {
    --fc-border-color: rgb(255 255 255 / 25%);
  }

  eaw-coveo-search {
    --atomic-background: var(--eab-gray-a-600);
    --atomic-neutral: var(--eab-gray-a);
    --atomic-neutral-light: var(--eab-gray-a-400);
    --atomic-neutral-dark: var(--eab-gray-d);
    --atomic-on-background: var(--eaw-body-color);
    --atomic-primary: #85A2FA;
    --atomic-primary-dark: #{shade-color(#85A2FA, 20%)};
    --atomic-primary-light: #{tint-color(#85A2FA, 20%)};
    --content-url-color: var(--eab-gray-b-300);
    --divider-color: var(--eab-gray-a);
    --ea-source-color: var(--eab-gray-b-100);
    --excerpt-color: var(--eab-gray-b-100);
    --field-color: var(--eab-gray-b-300);
    --security-notification-bg: var(--eab-gray-a-400);
    --vpn-tag-bg: var(--eab-gray-a);
    --vpn-tag-color: var(--eab-orange-300);

    atomic-query-summary,
    atomic-sort-dropdown {
      --atomic-on-background: var(--eaw-body-color);
    }

    eaw-voice-to-text {
      --icon-color: var(--eab-gray-d);
      --icon-hover-bg: rgb(255 255 255 / 25%);
    }

    .popover-nested::part(facet) {
      --atomic-neutral-light: rgb(255 255 255 / 25%);
      --tw-shadow: 0px 2px 8px rgb(255 255 255 / 25%);
    }
  }

  eaw-form-input {
    input {
      color-scheme: dark;
    }
  }

  eaw-global-footer {
    --bg-color: var(--eab-gray-a);
    --color: var(--eab-gray-e);
  }

  eaw-header-search-dropdown {
    --bg-color: var(--eab-gray-a-400);
  }

  eaw-profile-dropdown,
  eaw-theme-switcher {
    --dropdown-item-hover-bg: rgb(255 255 255 / 10%);
  }

  eaw-business-apps-list {
    --title-color: var(--eab-gray-d);
  }

  eaw-directory-content-list {
    --card-title-color: var(--eaw-link-color);
    --filter-dropdown-bg: var(--eaw-body-bg-secondary);
    --filter-dropdown-border-color: rgb(255 255 255 / 10%);
    --filter-dropdown-hover-bg: var(--eab-gray-a-400);
    --filter-options-accent-color: var(--eaw-link-color);
    --filter-options-bg: var(--eaw-card-bg);
    --filter-options-border-color: rgb(255 255 255 / 10%);
    --search-input-bg: rgb(255 255 255 / 15%);
    --search-input-border-color: rgb(255 255 255 / 25%);
  }

  .eaw-navigation-menu--parent,
  .eaw-navigation-menu--site {
    --color: var(--eab-gray-e);
    --divider-active-color: var(--eab-blue-200);
    --nav-link-active-color: var(--eab-blue-200);
    --nav-link-color: var(--eab-gray-e);
    --nav-toggler-color: var(--eab-gray-e);
    --sub-nav-bg-color: var(--eab-gray-a);
    --sub-menu-heading-color: var(--eab-gray-c);
  }

  .eaw-navigation-menu--parent {
    --bg-color: var(--eab-gray-a);
    --logo-color: #fff;
    --navbar-open-logo-and-toggle-bg: var(--eab-gray-a-400);
  }

  .eaw-navigation-menu--site {
    --color: var(--eab-gray-e);
    --navbar-collapse-bg-color: var(--eab-gray-a);
    --nested-sub-nav-bg-color: var(--eab-gray-a-700);
    --nested-sub-nav-item-hover-bg-color: var(--eab-gray-a-900);
    --nested-sub-nav-toggle-expanded-bg-color: var(--eab-gray-a-900);
    --sub-nav-item-hover-bg-color: var(--eab-gray-a-800);
  }

  .contextual-actions,
  .lumx-button-wrapper--color-dark {
    background-color: var(--eab-gray-a-400);
  }

  .editable-media--edit-below .editable-media__actions a,
  .editable-media--edit-below .editable-media__actions button,
  .lumx-input-label,
  .ls-date-picker__days-of-week span,
  .lumx-select__choices-filter input,
  .lumx-select__choices-filter input::placeholder,
  .lumx-switch.lumx-switch--is-unchecked .lumx-switch__input-background,
  .lumx-radio-button.lumx-radio-button--is-unchecked .lumx-radio-button__input-background,
  .text-field .text-field__label,
  .text-field .text-field__input,
  .text-field__input::placeholder {
    color: var(--eaw-body-color);
  }

  .lumx-switch.lumx-switch--is-unchecked .lumx-switch__input-indicator {
    background-color: var(--eaw-body-color);
  }

  .text-field:before {
    background-color: rgb(255 255 255 / 25%);
  }

  .lumx-chip--is-unselected.lumx-chip--color-dark .lumx-chip__label {
    color: var(--eaw-body-color);
  }

  .lumx-chip.lumx-chip--is-selected.lumx-chip--color-dark {
    background-color: rgb(var(--eaw-link-color-rgb) / 25%);
    color: var(--eaw-link-color);
  }

  .dropdown-menu,
  .lumx-dialog__wrapper,
  .lumx-dropdown__menu,
  .lumx-popover {
    background-color: var(--eab-gray-a-400);
    color: var(--eaw-body-color);

    .dropdown-divider {
      background-color: var(--eaw-lumapps-divider-color);
    }

    .dropdown-link,
    .dropdown-link i,
    .lumx-button,
    .lumx-icon,
    .lumx-text,
    .notification-list-item--clickable,
    .notification-list-item__content {
      color: inherit;
    }

    .lumx-button:not(.lumx-button--is-selected) {
      background-color: var(--eab-gray-a-300);

      &.lumx-button--color-primary {
        background-color: var(--eab-blue-300);
      }
    }

    .lumx-button:not(.lumx-button--is-selected):hover,
    .lumx-button:not(.lumx-button--is-selected)[class*="--is-hovered"] {
      background-color: var(--eab-gray-a);
      color: var(--eaw-body-color);

      &.lumx-button--color-primary {
        background-color: var(--eab-blue-400);
      }
    }

    .lumx-icon.lumx-icon--has-shape {
      background-color: rgb(var(--eaw-body-color-rgb) / 10%);
    }

    .lumx-list-item__link {
      color: var(--eaw-body-color);
    }

    .lumx-list-item__link:hover,
    .lumx-list-item__link[data-focus-visible-added] {
      background-color: rgb(var(--eab-gray-a-rgb) / 50%);
    }

    .lumx-list-item__link--is-selected {
      background-color: var(--eab-gray-a);
    }

    .lumx-list-subheader,
    .lumx-menu-section__header {
      color: rgb(var(--eaw-body-color-rgb) / 50%);
    }

    .lumx-menu .lumx-menu-section:not(:last-child):not(:has(ul:empty)),
    .lumx-select__choices-filter {
      border-color: var(--eaw-lumapps-divider-color);
    }

    .lumx-select__choices-helper {
      color: rgb(var(--eaw-body-color-rgb) / 75%);
    }
  }

  .lumx-dialog__overlay {
    background-color: rgb(0 0 0 / 50%);
  }

  .lumx-dialog__wrapper {
    box-shadow: 0 0 1rem 0.5rem rgb(255 255 255 / 5%);
  }

  .lumx-tooltip {
    --lumx-color-dark-N: var(--eab-gray-b);

    .lumx-tooltip__inner {
      color: var(--eaw-body-color);
    }
  }

  .component-action__btn {
    --lumx-button-emphasis-low-state-default-theme-light-color: #000;
    --lumx-button-emphasis-low-state-hover-theme-light-color: #000;
    --lumx-button-emphasis-low-state-hover-theme-light-background-color: var(--eab-gray-a-200);

    background-color: var(--eab-gray-a-100);
  }

  .sidebar-content {
    background-color: var(--eab-gray-b);

    .card {
      color: var(--eaw-body-color-dark);
    }

    .btn--icon.btn--black:not(.lumx-button),
    .custom-accordion-box--simple.custom-accordion-box--primary .custom-accordion-box__label,
    .lumx-button:not(.lumx-button--is-selected).lumx-button--emphasis-low,
    .lumx-button:not(.lumx-button--is-selected).lumx-button--emphasis-medium,
    .lumx-chip--is-unselected.lumx-chip,
    .lumx-chip--is-unselected.lumx-chip .lumx-chip__label,
    .lumx-message__text,
    .lumx-select .lumx-select__input-indicator,
    .lumx-select .lumx-select__input-native,
    .lumx-text-field.lumx-text-field--is-focus .lumx-text-field__label,
    .lumx-text-field .lumx-text-field__input-icon,
    .lumx-text-field .lumx-text-field__input-native,
    .widget-style-area__position .icon {
      color: var(--eaw-body-color);
    }

    .accordion-box,
    .custom-accordion-box__content,
    .custom-accordion-box--simple,
    .lumx-checkbox__input-background,
    .lumx-select .lumx-select__wrapper:before,
    .lumx-text-field .lumx-text-field__wrapper:before,
    .sidebar-helper,
    .sidebar-content__toolbar,
    .widget-style-area__settings-wrapper .has-divider {
      border-color: var(--eaw-lumapps-divider-color);
    }

    .custom-tabs__state,
    .custom-tabs__tab {
      background-color: rgb(0 0 0 / 25%);
      color: rgb(var(--eaw-body-color-rgb) / 50%);
    }

    .custom-tabs__state--is-active,
    .custom-tabs__tab--is-active {
      background-color: rgb(0 0 0 / 15%);
      color: inherit;
    }

    .custom-tabs__panes {
      color: var(--eaw-body-color);
      background-color: rgb(0 0 0 / 15%);

      .custom-select__label {
        color: inherit;
      }

      .custom-select__selection {
        background-color: rgb(0 0 0 / 15%);
        // color: inherit;

        &:after {
          color: inherit;
        }

        .custom-select__placeholder {
          color: inherit;
        }
      }

      .lumx-radio-button.lumx-radio-button--is-unchecked .lumx-radio-button__input-background,
      .lumx-input-label,
      .uploader__placeholder i,
      .uploader__placeholder span {
        color: var(--eab-gray-b);
      }

      .lumx-input-helper {
        color: rgb(var(--eab-gray-b-rgb) / 75%);
      }
    }

    .color-palette {
      margin: 0;
    }

    .color-palette__color-label {
      border-top: 1px solid rgb(var(--eaw-body-color-rgb) / 25%);
      padding: 0 4px;
      text-align: center;
    }

    .color-palette__color-wrapper {
      background-color: rgb(0 0 0 / 25%);
      border: 1px solid rgb(var(--eaw-body-color-rgb) / 25%);
      box-shadow: none;
    }

    .lumx-switch--is-checked {
      .lumx-switch__input-background {
        background-color: var(--eaw-link-color);
      }

      .lumx-switch__input-native:hover + .lumx-switch__input-placeholder .lumx-switch__input-background {
        background-color: var(--eaw-link-color-hover);
      }
    }

    .lumx-tabs .lumx-tabs__link--is-active {
      color: var(--eaw-body-color);

      &:after {
        border-color: rgb(var(--eaw-body-color-rgb) / 75%);
      }
    }

    .lumx-tabs .lumx-tabs__link:not(.lumx-tabs__link--is-active):not(.lumx-tabs__link--is-disabled) {
      color: rgb(var(--eaw-body-color-rgb) / 50%);

      &:after {
        border-color: var(--eaw-lumapps-divider-color);
      }
    }

    .component-settings__more-link,
    .custom-accordion-box__chevron,
    .lumx-input-helper,
    .sidebar-helper,
    .sidebar-revision__version,
    .text-field-help,
    .lumx-text-field--is-disabled.lumx-text-field .lumx-text-field__helper,
    .lumx-text-field--is-disabled.lumx-text-field .lumx-text-field__label {
      color: rgb(var(--eaw-body-color-rgb) / 75%);
    }

    .tc-black-2,
    .tc-black-3 {
      color: rgb(var(--eaw-body-color-rgb) / 75%) !important; //LumApps styles use important, so we need to as well
    }

    .sidebar-content__toolbar {
      background-color: var(--eab-gray-a-400);
    }

    .sidebar-revision__item {
      border-color: var(--eaw-lumapps-divider-color);
    }

    .sidebar-revision__item--is-active {
      border-color: #fff;
    }

    .widget-style--is-focus .widget-style-area__outside,
    .widget-style--main-open .widget-style-area__outside,
    .widget-style--is-focus .widget-style-content__outside,
    .widget-style--main-open .widget-style-content__outside,
    .widget-style-area--is-active .widget-style-area__inside,
    .widget-style-area__inside:hover {
      background-color: rgb(255 255 255 / 25%);
      border-color: rgb(255 255 255 / 50%);
    }

    .widget-style-area__inside,
    .widget-style-content__inside {
      background-color: rgb(255 255 255 / 15%);
      border-color: rgb(255 255 255 / 25%);
    }

    .widget-style-area--is-active .widget-style-area__inside:after,
    .widget-style-content--is-active .widget-style-content__wrapper .widget-style-content__inside:after {
      background-color: rgb(255 255 255 / 50%);
    }

    .widget-style-area.widget-style-area--header.widget-style-area--is-active,
    .widget-style--main-open .widget-style-area--footer .widget-style-area__wrapper {
      border-color: rgb(255 255 255 / 50%);
    }

    .widget-style-area__outside,
    .widget-style-content__outside {
      border-color: rgb(255 255 255 / 25%);
    }

    .widget-style-area--is-active .widget-style-area__icon,
    .widget-style-area--is-active .widget-style-area__label,
    .widget-style-area__inside:hover .widget-style-area__label {
      border-color: rgb(255 255 255 / 75%);
    }

    .widget-style-area__icon,
    .widget-style-area__label {
      background-color: rgb(255 255 255 / 25%);
      border-color: rgb(255 255 255 / 50%);
    }

    .widget-style-area__position button:hover {
      background-color: rgb(0 0 0 / 50%);
    }

    .uploader__placeholder {
      i, span {
        color: var(--eaw-body-color);
      }
    }
  }

  .sidebar-header {
    background-color: var(--eaw-body-bg-secondary);
  }

  .sidebar-toggle {
    background-color: var(--eab-gray-a-400);
    border-color: var(--eaw-lumapps-divider-color);
  }
}
