.widget--flex {
  --eaw-html-flex-gap: 32px;

  .block-html,
  .widget-html__content,
  .widget-html__wysiwyg .fr-view {
    img {
      display: block;
      margin: 0;
      max-width: 100%;
      width: 100% !important; // Must be important to override any inline LumApps styles
    }

    > p,
    > div {
      display: grid;
      gap: var(--eaw-html-flex-gap);
      grid-template-columns: repeat(var(--eaw-html-flex-col-count, 1), 1fr);

      > p {
        margin-bottom: 0;
      }

      + div,
      + p {
        margin-top: var(--eaw-html-flex-gap);
      }
    }
  }
}

.widget--flex--2 {
  --eaw-html-flex-col-count: 2;
}

.widget--flex--3 {
  --eaw-html-flex-col-count: 3;
}

.widget--flex--4 {
  --eaw-html-flex-col-count: 4;
}

.widget--flex--5 {
  --eaw-html-flex-col-count: 5;
}

.widget--flex--6 {
  --eaw-html-flex-col-count: 6;
}

.block-html,
.widget-html__content,
.widget-html__wysiwyg .fr-view {
  .eaw-flex-row {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-right: calc(var(--eaw-flex-grid-gutter-x) / -2);
    margin-left: calc(var(--eaw-flex-grid-gutter-x) / -2);
    margin-top: calc(var(--eaw-flex-grid-gutter-y) * -1);

    > * {
      flex-shrink: 0;
      max-width: 100%;
      padding-right: calc(var(--eaw-flex-grid-gutter-x) / 2);
      padding-left: calc(var(--eaw-flex-grid-gutter-x) / 2);
      margin-top: var(--eaw-flex-grid-gutter-y);
      width: 100%;
    }

    // + .eaw-flex-row {
    //   margin-top: calc(var(--eaw-flex-grid-gutter-y) * -1);
    // }
  }

  .eaw-flex-row--center {
    align-items: center;
  }

  @include media-breakpoint-up('lg') {
    .eaw-flex-col {
      flex: 1 0 0%;
    }

    .eaw-flex-col-1 {
      flex: 0 0 auto;
      width: 8.3333333333%;
    }

    .eaw-flex-col-2 {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }

    .eaw-flex-col-3 {
      flex: 0 0 auto;
      width: 25%;
    }

    .eaw-flex-col-4 {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }

    .eaw-flex-col-5 {
      flex: 0 0 auto;
      width: 41.6666666667%;
    }

    .eaw-flex-col-6 {
      flex: 0 0 auto;
      width: 50%;
    }

    .eaw-flex-col-7 {
      flex: 0 0 auto;
      width: 58.3333333333%;
    }

    .eaw-flex-col-8 {
      flex: 0 0 auto;
      width: 66.6666666667%;
    }

    .eaw-flex-col-9 {
      flex: 0 0 auto;
      width: 75%;
    }

    .eaw-flex-col-10 {
      flex: 0 0 auto;
      width: 83.3333333333%;
    }

    .eaw-flex-col-11 {
      flex: 0 0 auto;
      width: 91.6666666667%;
    }

    .eaw-flex-col-12 {
      flex: 0 0 auto;
      width: 100%;
    }
  }
}
