.widget-content-list {
  .content-block-excerpt,
  .user-block--theme-light .user-block__primary-field {
    color: var(--eaw-content-list-excerpt-color, rgb(var(--eaw-widget-color-rgb) / 87%));
  }

  .block-page-preview__title {
    --lumx-typography-custom-title3-font-size: #{map-get($font-sizes, '16px')};
    --lumx-typography-custom-title3-line-height: #{map-get($font-sizes, '24px')};
  }

  .block-page-preview__content {
    --lumx-typography-custom-body-font-size: #{map-get($font-sizes, '14px')};
    --lumx-typography-custom-body-line-height: #{map-get($font-sizes, '20px')};

    .block-page-preview__date,
    .block-page-preview__tags,
    .lumx-user-block__field,
    .metadata-link {
      --lumx-tag-state-default-theme-light-color: rgb(var(--eaw-widget-color-rgb) / 65%);

      color: rgb(var(--eaw-widget-color-rgb) / 65%);
    }

    .block-page-preview__excerpt {
      color: rgb(var(--eaw-widget-color-rgb) / 85%);
    }

    .block-reactions {
      .lumx-button {
        color: rgb(var(--eaw-widget-color-rgb) / 85%);

        &:hover {
          .lumx-icon {
            color: var(--eaw-link-color-hover);
          }
        }
      }

      .like-reaction-button {
        &:hover {
          .lumx-icon {
            color: var(--eab-red-400);
          }
        }

        &.like-reaction-button--is-liked {
          color: var(--eab-red-400);

          .lumx-icon {
            color: inherit;
          }
        }
      }
    }
  }

  .content-block-excerpt {
    --lumx-typography-custom-body-font-size: #{map-get($font-sizes, '14px')};
    --lumx-typography-custom-body-line-height: #{map-get($font-sizes, '20px')};

    color: rgb(var(--eaw-body-color-rgb) / 75%);
  }

  .content-block--default-style.content-block {
    border-color: rgb(var(--eaw-widget-color-rgb) / 25%);

    .content-block-title a {
      color: var(--eaw-link-color);

      &:hover {
        color: var(--eaw-link-color-hover);
      }
    }

    .content-block__field--publication-date .content-block-publication-date {
      color: rgb(var(--eaw-widget-color-rgb) / 65%);
    }

    .like-button__btn {
      color: rgb(var(--eaw-widget-color-rgb) / 50%);
    }
  }
}
