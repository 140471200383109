:root {
  --eaw-alert-bg: transparent;
  --eaw-alert-border-color: transparent;
  --eaw-alert-border-radius: 0.375rem;
  --eaw-alert-color: inherit;
  --eaw-alert-margin-bottom: 1rem;
  --eaw-alert-padding-y: 1rem;
  --eaw-alert-padding-x: 1rem;

  @each $color, $value in map-sort($alert-colors) {
    $alert-color: shade-color($value, 60%);
    $bg: tint-color($value, 80%);
    $border: tint-color($value, 60%);

    @if $color == 'light' {
      $alert-color: shade-color($value, 80%);
      $bg: tint-color($value, 20%);
      $border: shade-color($value, 10%);
    }

    --eaw-alert-#{$color}-bg: #{$bg};
    --eaw-alert-#{$color}-border-color: #{$border};
    --eaw-alert-#{$color}-color: #{$alert-color};
  }
}

html[data-eaw-theme=dark] {
  @each $color, $value in map-sort($alert-colors) {
    $alert-color: tint-color($value, 40%);
    $bg: shade-color($value, 80%);
    $border: shade-color($value, 40%);

    @if $color == 'dark' {
      $alert-color: tint-color($value, 80%);
      $bg: mix($value, $black);
      $border: tint-color($value, 10%);
    }

    --eaw-alert-#{$color}-bg: #{$bg};
    --eaw-alert-#{$color}-border-color: #{$border};
    --eaw-alert-#{$color}-color: #{$alert-color};
  }
}

.eaw-alert {
  background-color: var(--eaw-alert-bg);
  border: 1px solid var(--eaw-alert-border-color);
  border-radius: var(--eaw-alert-border-radius);
  color: var(--eaw-alert-color);
  margin-bottom: var(--eaw-alert-margin-bottom);
  padding: var(--eaw-alert-padding-y) var(--eaw-alert-padding-x);
  position: relative;
}

@each $color, $value in map-sort($alert-colors) {
  .eaw-alert-#{$color} {
    --eaw-alert-bg: var(--eaw-alert-#{$color}-bg);
    --eaw-alert-border-color: var(--eaw-alert-#{$color}-border-color);
    --eaw-alert-color: var(--eaw-alert-#{$color}-color);
  }
}
