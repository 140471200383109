html[data-eaw-theme=dark] {
  #ea-intranet-home {
    background-color: transparent;

    .component-row--business-apps-list {
      background-color: var(--eaw-body-bg-tertiary);
    }

    .component-row--main {
      --eaw-headings-color: var(--eab-gray-d);
      --eaw-widget-bg: var(--eaw-body-bg-secondary);
      --eaw-widget-header-bg: var(--eab-gray-a);
      --eaw-widget-footer-bg: var(--eab-gray-a);
    }
  }
}

html[data-eaw-theme=light] {
  #ea-intranet-home {
    .widget--our-impact,
    .widget--upcoming-events,
    .widget--webinars {
      --eaw-widget-bg: #fff;
    }

    .block-slideshow__controls,
    .widget-slideshow__controls {
      background-color: var(--eaw-widget-footer-bg);
    }
  }

  .component-row--main {
    --eaw-body-color: var(--eab-gray-b);
    --eaw-headings-color: var(--eab-gray-b);
    --eaw-widget-header-bg: #fff;
    --eaw-widget-footer-bg: #fff;
  }
}

#ea-intranet-home {
  --default-content-padding: 16px;
  --eaw-header-background: var(--eab-blue);
  --eaw-header-overlay-opacity: 1;

  background: var(--eab-gray-d-100);

  // Remove the extra space that is usually at the bottom of the hero row for the site level navigation
  .component-row--is-root:first-child,
  .row[data-id=layout-row0] {
    .widget-content {
      border-bottom: none;
    }
  }

  .component-cell__components,
  .cell__wrapper {
    row-gap: 24px;
  }

  // Hide the search button in the header since the home page already has its own search box
  .eaw-header-btn-search {
    display: none;
  }

  .component-row--business-apps-list {
    background-color: #fff;
    margin-bottom: 40px;

    @include media-breakpoint-up('lg') {
      margin-bottom: 0;
    }

    .component-widget {
      margin: 0;
    }
  }

  .component-row--main {
    --eaw-widget-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);

    .right-arrow {
      font-size: map-get($font-sizes, '14px');
    }

    .widget-html .widget-content {
      padding: var(--default-content-padding);
    }

    .block-html,
    .widget-html__content,
    .widget-html__wysiwyg .fr-view {
      img.fr-dii.fr-fil {
        margin-right: 16px;
      }
    }
  }

  .lumx-slideshow-controls,
  .widget-slideshow__controls {
    bottom: 0;
    height: 36px;
    left: 0;
    margin: 0;
    position: absolute;
    right: 0;
  }

  .lumx-slideshow-controls .lumx-button {
    color: var(--eaw-body-color);
  }

  .lumx-slideshow-controls__pagination {
    max-width: none;
  }

  .lumx-slideshow-controls__pagination-items,
  .widget-slideshow__pager {
    column-gap: 8px;
  }

  .widget--featured-news {
    --lumx-typography-custom-body-font-size: #{map-get($font-sizes, '14px')};
    --lumx-typography-custom-body-line-height: #{map-get($font-sizes, '20px')};

    .block-page-preview--size-l.block-page-preview--side .block-page-preview__thumbnail-wrapper {
      flex-basis: 50%;
      width: 50%;
    }

    .block-page-preview--size-l.block-page-preview--side .block-page-preview__content {
      flex-basis: 50%;
    }

    .block-slideshow--with-navigation .block-page-preview--side .block-page-preview__content {
      padding-bottom: 24px;
    }

    .block-slideshow--side .block-page-preview__content {
      @include media-breakpoint-up('lg') {
        padding: 12px 12px 12px 24px;
      }
    }

    .lumx-slideshow-controls {
      bottom: unset;
      top: 100%;
    }

    .content-block--view-mode-cover {
      .content-block__fields {
        @include media-breakpoint-up('lg') {
          max-width: 50%;
          padding: 12px 12px 12px 24px;
        }
      }

      .content-block__thumbnail {
        min-width: 50%;
      }

      .content-block-title a {
        font-size: map-get($font-sizes, '16px');
        font-weight: bold;
        letter-spacing: 0.8px;
        line-height: map-get($font-sizes, '24px');
      }
    }

    .widget-content {
      padding-bottom: 36px;
    }
  }

  .widget-footer__label {
    align-items: center;
    display: flex;
    font-size: map-get($font-sizes, '12px');
    gap: 6px;
    letter-spacing: 0.8px;
    line-height: map-get($font-sizes, '12px');

    &:hover {
      --arrow-margin: 6px;
    }

    &:after {
      -webkit-font-smoothing: antialiased;
      font-family: "Font Awesome 5 Pro";
      font-weight: 300;
      content: "\f061";
      text-rendering: auto;
      line-height: 1;
      margin-left: var(--arrow-margin, 0);
      transition: margin 250ms;
    }
  }

  .widget-footer__wrapper {
    border: none;
    justify-content: center;
  }

  .widget-header__wrapper {
    border: none;
    margin: 0;
    padding: 8px 12px;
  }

  .widget-header__title {
    color: var(--eab-gray-d-600);
    font-size: map-get($font-sizes, '12px');
    font-weight: bold;
    letter-spacing: 0.8px;
    line-height: map-get($font-sizes, '16px');
    text-transform: uppercase;
  }

  .widget--our-impact {
    .widget-content {
      background-repeat: no-repeat;
      background-size: 100% 150px !important;
      padding-top: 166px !important;
    }
  }

  .widget-html.widget--upcoming-events {
    --date-header-width: 70px;

    .widget-content {
      padding-top: 2px;
    }

    eaw-upcoming-events header {
      padding: 0;
    }

    .eaw-upcoming-events__header-text {
      display: none;
    }

    .eaw-upcoming-events__header-buttons {
      --button-height: 20px;

      position: absolute;
      right: 0;
      top: -26px;
    }

    .eaw-upcoming-events__date-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 8px 0 0;

      width: var(--date-header-width);
    }

    .eaw-upcoming-events__date-header-date {
      margin-top: 0;
    }

    .eaw-upcoming-events__event {
      margin: 6px 0;
      width: calc(100% - var(--date-header-width));
    }
  }

  .widget--webinars {
    .block-page-preview__content {
      padding: var(--default-content-padding);
    }

    .block-page-preview--vertical .block-page-preview__thumbnail-wrapper {
      margin-bottom: 0;
    }

    .lumx-thumbnail__background {
      height: 150px;
    }

    .widget-content-list__list--horizontal {
      .content-block__fields {
        padding: var(--default-content-padding);
      }

      .content-block__thumbnail {
        height: 150px;
        margin: 0;
      }

      .widget-slideshow {
        padding: 0;
      }

      .widget-slideshow__block {
        padding: 0;
      }
    }

    .widget-content {
      padding-bottom: 36px;
    }
  }
}
