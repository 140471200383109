@mixin fa-icon($icon, $position: 'relative', $font-family: 'Font Awesome 5 Pro', $font-weight: normal) {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: $font-family;
  font-style: normal;
  font-variant: normal;
  font-weight: $font-weight;
  text-rendering: auto;
  line-height: 1;

  @if ($position == 'absolute') {
    content: "#{map-get($fa-icons, $icon)}";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  @else if ($position == 'inline-block') {
    content: "#{map-get($fa-icons, $icon)}";
    display: inline-block;
  }
  @else {
    display: inline-block;

    &:before {
      content: "#{map-get($fa-icons, $icon)}";
    }
  }
}

@mixin fa-icon-brand($icon, $position: 'relative') {
  @include fa-icon($icon, $position, 'Font Awesome 5 Brands');
}

@mixin fa-icon-light($icon, $position: 'relative') {
  @include fa-icon($icon, $position, $font-weight: 300);
}

@mixin fa-icon-solid($icon, $position: 'relative') {
  @include fa-icon($icon, $position, $font-weight: 900);
}
