// Might need to implement overrides for the below
.theme-lumapps .content-block-excerpt--formatted a,
.theme-lumapps .widget-html__content a,
.theme-lumapps .widget-html__wysiwyg .fr-view a,
.theme-lumapps .widget-tip__content a,
.theme-lumapps .widget-tip__wysiwyg .fr-view a {
  color: var(--eaw-link-color);
}

.widget-html {
  --eaw-widget-content-overflow: visible;
  --eaw-widget-overflow: visible;

  #{$widget-html-with-wysiwyg-content-selector} {
    @extend %base-html-widget;

    .eaw-card {
      background-color: var(--eaw-card-bg);
      border: var(--eaw-card-border-width) solid var(--eaw-card-border-color);
      border-radius: var(--eaw-card-border-radius);
      box-shadow: var(--eaw-card-shadow);
      display: block;
      padding: var(--eaw-card-padding-y) var(--eaw-card-padding-x);
      text-align: center;
      transition: box-shadow 250ms ease-in-out, transform 250ms ease-in-out;

      img {
        margin-bottom: 1rem;
      }
    }

    a.eaw-card {
      text-decoration: none;

      .app-content:not(.app-content--designer-mode) & {
        &:focus,
        &:hover {
          box-shadow: var(--eaw-card-cta-hover-shadow);
          transform: translate3d(0, var(--eaw-card-cta-hover-shift-y), 0);
        }
      }
    }
  }

  #{$widget-html-content-selector} {
    ul.check-list {
      list-style: none;
      margin-left: 0;
      padding: 0;

      > li {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMCAwaDI0djI0SDB6Ii8+PHBhdGggc3Ryb2tlPSIjNDlDNzc3IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMS41IiBkPSJtMTcuNSA4LjctNi42IDYuNi00LjQtNC40Ii8+PHBhdGggc3Ryb2tlPSIjNDlDNzc3IiBzdHJva2Utd2lkdGg9IjEuNSIgZD0iTTIzLjI1IDEyQzIzLjI1IDUuNzg3IDE4LjIxNC43NSAxMiAuNzVTLjc1IDUuNzg3Ljc1IDEyYzAgNi4yMTUgNS4wMzUgMTEuMjUgMTEuMjUgMTEuMjVTMjMuMjUgMTguMjE1IDIzLjI1IDEyWiIvPjwvZz48L3N2Zz4=');
        background-repeat: no-repeat;
        padding-left: 50px;
        margin-top: 20px;
      }
    }
  }

  video {
    max-width: 100%;
  }

  // Legacy LumApps UI selector
  .widget-html__content {
    // For some reason the cursor is being set as a pointer in LumApps, so we need to reset it to default
    img {
      cursor: auto;
    }

    // But we still want to show the pointer when the image is within a link.
    a img {
      cursor: pointer;
    }
  }

  // Set sensible defaults for html widget editor
  // Legacy LumApps UI selector
  .widget-html__wysiwyg .fr-view {
    --eaw-link-color: #{map-get($ea-brand-colors, 'blue')};
    --eaw-link-color-hover: #{map-get($ea-brand-colors, 'teal')};
    --eaw-widget-color: #000;
    --eaw-widget-color-rgb: 0 0 0;
    --eaw-widget-heading-color: #000;

    background-color: #fff;

    // Since we are resetting the cursor above, lets set it back to a pointer for edit mode.
    img {
      cursor: pointer;
    }
  }
}

@media (min-width: 1024px) {
  .component-row:not(.component-row--no-inset) {
    .component-row__wrapper:not(.component-row__wrapper--is-fluid) {
      .component-cell--w-12 > .component-cell__components > .component-cell__component > .component-widget > .widget-html {
        margin-left: auto;
        margin-right: auto;
        max-width: var(--eaw-widget-max-width, 936px);
      }
    }
  }
  .row:not(.component-row--no-inset) {
    .row-wrapper:not(.row-wrapper--is-full-width) {
      .cell--w-12 > .cell__wrapper > .widget-html {
        margin-left: auto;
        margin-right: auto;
        max-width: var(--eaw-widget-max-width, 936px);
      }
    }
  }
}

// Use a placeholder selector to hold the basic styles for the html widget. This way we can also use these for the rich text widget
%base-html-widget {
  // color: var(--eaw-widget-color);
  // font-family: var(--eaw-widget-font-family);
  // font-size: var(--eaw-widget-font-size);
  // letter-spacing: var(--eaw-widget-letter-spacing);
  // line-height: var(--eaw-widget-line-height);

  blockquote,
  dl,
  ol,
  ul,
  p,
  section,
  table {
    margin-bottom: 0;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  blockquote {
    color: rgb(var(--eaw-widget-color-rgb) / 85%);
    border-left: 5px solid rgb(var(--eaw-widget-color-rgb) / 35%);

    &:before {
      display: none;
    }
  }

  .lead {
    font-size: map-get($font-sizes, '20px');
  }

  ol,
  ul {
    padding-left: 2rem;
  }

  ol,
  ul,
  dl {
    margin-left: 0;
    margin-top: 0;
  }

  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0;
  }

  dd {
    margin-bottom: .5rem;
    margin-left: 1rem;
  }

  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-bottom: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--eaw-widget-heading-color); // Keeping the widget-heading-color property instead of just the heading-color property for backwards compatibility
    font-family: var(--eaw-headings-font-family);
    font-weight: var(--eaw-headings-font-weight);
    letter-spacing: var(--eaw-headings-letter-spacing);
    margin-bottom: 0;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  h1 {
    --eaw-clamp-line-height: var(--eaw-heading1-line-height);

    font-size: var(--eaw-heading1-font-size);
    line-height: var(--eaw-heading1-line-height);
  }

  h2 {
      --eaw-clamp-line-height: var(--eaw-heading2-line-height);

    font-size: var(--eaw-heading2-font-size);
    line-height: var(--eaw-heading2-line-height);
  }

  h3 {
    --eaw-clamp-line-height: var(--eaw-heading3-line-height);

    font-size: var(--eaw-heading3-font-size);
    line-height: var(--eaw-heading3-line-height);
  }

  h4 {
    --eaw-clamp-line-height: var(--eaw-heading4-line-height);

    font-size: var(--eaw-heading4-font-size);
    line-height: var(--eaw-heading4-line-height);
  }

  h5 {
    --eaw-clamp-line-height: var(--eaw-heading5-line-height);

    font-size: var(--eaw-heading5-font-size);
    line-height: var(--eaw-heading5-line-height);
  }

  h6 {
    --eaw-clamp-line-height: var(--eaw-heading6-line-height);

    font-size: var(--eaw-heading6-font-size);
    line-height: var(--eaw-heading6-line-height);
  }

  hr {
    border-bottom: 1px solid rgb(var(--eaw-widget-color-rgb) / 25%);
  }

  table {
    // --eaw-link-color: #{$link-color};

    border-collapse: collapse;
    border-color: var(--eaw-widget-table-border-color);
    caption-side: bottom;
    width: 100%;

    > tbody {
      vertical-align: inherit;
    }

    > thead {
      vertical-align: bottom;
    }

    thead,
    tbody,
    tfoot,
    tr,
    td,
    th {
      border-color: inherit;
      border-style: solid;
      border-width: 0;
    }

    tbody {
      color: var(--eaw-body-color, #000);

      tr {
        &:nth-of-type(odd) {
          --eaw-widget-table-bg: var(--eaw-widget-table-alt-bg);
        }

        // Remove the default lumapps table row background color
        &:nth-child(2n) {
          --lumx-color-dark-L6: transparent; // NGI override
          background-color: transparent;
        }
      }
    }

    td,
    th {
      background-color: var(--eaw-widget-table-bg);
      border: 1px solid var(--eaw-widget-table-border-color);
      padding: var(--eaw-widget-table-padding);
    }

    th {
      background-color: var(--eaw-widget-table-head-bg);
      color: var(--eaw-widget-table-head-color);
    }
  }
}
