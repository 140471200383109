:root {
  --eaw-btn-border-radius: 4px;
  --eaw-btn-border-width: 1px;
  --eaw-btn-disabled-opacity: 0.65;
  --eaw-btn-focus-shadow: 0 0 0 0.25rem rgba(var(--eaw-btn-focus-shadow-rgb), .5);
  --eaw-btn-font-size: #{map-get($font-sizes, '14px')};
  --eaw-btn-font-weight: 400;
  --eaw-btn-line-height: 1.5;
  --eaw-btn-padding-x: 0.75rem;
  --eaw-btn-padding-y: 0.375rem;
  @include button-variant(#0d6efd);
}

.widget-html .block-html .eaw-btn,
.widget-html .widget-html__content .eaw-btn,
.widget-html .widget-html__wysiwyg .fr-view .eaw-btn,
.eaw-btn {
  @include eaw-button();
}

.component-row--btn {
  #{$widget-html-content-selector} {
    a {
      @include eaw-button();
      display: block;
      font-size: 1rem;
      margin: 0 auto;
      max-width: 450px;
      padding: 1rem;
    }
  }
}

.component-row--btn--outline {
  #{$widget-html-content-selector} {
    a {
      background-color: transparent;
      color: var(--eaw-btn-bg);
    }
  }
}
