@import "./designer-mode";
@import "./kb-faqs";

.eaw-16x9-container {
  position: relative;
}

.eaw-16x9-container:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 56.25%;
}

.eaw-16x9-container .eaw-16x9-inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.eaw-16x9-container .eaw-16x9-inner embed,
.eaw-16x9-container .eaw-16x9-inner iframe,
.eaw-16x9-container .eaw-16x9-inner object,
.eaw-16x9-container .eaw-16x9-inner video {
  min-height: 150px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.content-details {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  padding-bottom: 0;

  .component-row + .component-row {
    margin-top: var(--eaw-content-row-spacing);
  }

  .component-row:last-child {
    margin-bottom: var(--eaw-content-row-spacing);
  }
}

.layout {
  margin-bottom: 0;

  .row + .row {
    margin-top: var(--eaw-content-row-spacing);
  }

  > .row:last-child {
    margin-bottom: var(--eaw-content-row-spacing);
  }
}

.component-row--eaw-z-1 {
  z-index: 1;
}

.component-row--eaw-z-5 {
  z-index: 5;
}

.component-row--eaw-z-10 {
  z-index: 10;
}

.component-row--shadow-row {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
}

.component-row--text-dark,
.component-cell--text-dark,
.text-dark,
.widget--text-dark {
  --eaw-body-color: var(--eaw-body-color-dark);
  --eaw-body-color-rgb: var(--eaw-body-color-dark-rgb);
  --eaw-link-color: var(--eaw-link-color-dark);
  --eaw-link-color-hover: var(--eaw-link-color-dark-hover);
  --eaw-widget-color: var(--eaw-widget-color-dark);
  --eaw-widget-color-rgb: var(--eaw-widget-color-dark-rgb);
  --eaw-widget-header-color: var(--eaw-widget-header-color-dark);
  --eaw-widget-heading-color: var(--eaw-widget-heading-color-dark);

  .widget-html__wysiwyg .fr-view {
    --eaw-link-color: #000;
    --eaw-widget-color: #000;
    --eaw-widget-heading-color: #000;
  }
}

// The Lumapps "theme-dark" is meant for widgets that are displayed on a dark background, so the text should be light
.component-row--text-light,
.component-cell--text-light,
.text-light,
.widget--text-light,
.widget--theme-dark {
  --eaw-body-color: var(--eaw-body-color-light);
  --eaw-body-color-rgb: var(--eaw-body-color-light-rgb);
  --eaw-link-color: var(--eaw-link-color-light);
  --eaw-link-color-hover: var(--eaw-link-color-light-hover);
  --eaw-widget-color: var(--eaw-widget-color-light);
  --eaw-widget-color-rgb: var(--eaw-widget-color-light-rgb);
  --eaw-widget-header-color: var(--eaw-widget-header-color-light);
  --eaw-widget-heading-color: var(--eaw-widget-heading-color-light);

  .widget-html__wysiwyg .fr-view {
    --eaw-link-color: #000;
    --eaw-widget-color: #000;
    --eaw-widget-heading-color: #000;
  }
}

.component-row--section-heading {
  + .component-row,
  + .row {
    --eaw-content-row-spacing: 32px;
  }

  .block-html,
  .widget-html__content {
    text-align: center;
  }
}

.component-row--feature-grey {
  .row-wrapper {
    background-color: var(--eaw-body-bg-tertiary);
  }

  .cell {
    &:nth-child(2) {
      .widget {
        padding: 40px;
      }
    }
  }

  .cell__wrapper {
    padding: 0;
  }

  .component-cell {
    & > .component-cell__components {
      padding: 0;

      .component-widget {
        margin: 0;
      }
    }

    &:nth-child(2) {
      .widget-content {
        background-color:var(--eaw-body-bg-tertiary);
        padding: 40px !important;
      }
    }
  }
}

.component-row--info--icons {
  background-color: var(--eaw-body-bg-secondary);

  .widget-html .widget-content {
    background-position: 50% 24px !important; // Must be important because publishers can use the LumApps tools to set a background-image position
    background-repeat: no-repeat;
    background-size: 48px 48px !important; // Must be important because publishers can use the LumApps tools to set a background-size (fill, cover)
    text-align: center;
  }

  #{$widget-html-content-selector} {
    padding: 88px 24px 24px;
  }
}

.component-row--page-heading--slideshow {
  .cell:nth-child(1) .widget,
  .component-cell:nth-child(1) .component-cell__component {
    background-color: var(--eaw-body-bg-secondary);
    border: var(--eaw-card-border-width) solid var(--eaw-card-border-color);
    border-radius: var(--eaw-card-border-radius);
    box-shadow: var(--eaw-card-shadow);
  }

  .cell:nth-child(2) .widget,
  .component-cell:nth-child(2) .component-cell__component {
    border: var(--eaw-card-border-width) solid var(--eaw-card-border-color);
    border-radius: var(--eaw-card-border-radius);
    box-shadow: var(--eaw-card-shadow);
  }

  .widget-slideshow {
    flex: 1 1 auto;
    padding: 0;
  }

  .widget-slideshow__block .content-block__fields,
  .block-page-preview__content {
    padding: 16px 20px 20px;
  }

  .lumx-thumbnail--aspect-ratio-horizontal:not(.lumx-thumbnail--fill-height) .lumx-thumbnail__image {
    aspect-ratio: auto;
  }

  .lumx-thumbnail__image {
    border-top-left-radius: var(--eaw-card-border-radius);;
    border-top-right-radius: var(--eaw-card-border-radius);;
  }

  .block-page-preview--vertical .block-page-preview__thumbnail-wrapper,
  .content-block--view-mode-block .content-block__thumbnail {
    height: 328px;
    margin-bottom: 0;
    overflow: hidden;

    @media (max-width: 480px) {
      height: 180px;
    }

    @media (max-width: 420px) {
      height: 160px;
    }

    @media (max-width: 320px) {
      height: 140px;
    }
  }

  .widget-slideshow__block {
    padding: 0;

    .content-block-title {
      margin-bottom: 16px;

      a {
        color: var(--eaw-link-color);

        &:hover {
          color: var(--eaw-link-color-hover);
        }
      }
    }

    // .user-block__primary-field {
    //   // @include eaw-typography-p();
    //   color: #3A3B3B;
    // }

    // .user-block__secondary-field {
    //   // @include eaw-typography-label();
    //   color: #3A3B3B;
    // }
  }
}

.component-cell--bg-primary,
.component-row--bg-primary {
  background-color: var(--eaw-body-bg);
}

.component-cell--bg-secondary,
.component-row--bg-secondary {
  background-color: var(--eaw-body-bg-secondary);
}

.component-cell--bg-tertiary,
.component-row--bg-tertiary {
  background-color: var(--eaw-body-bg-tertiary);
}

@import "./alerts";
@import "./buttons";
@import "./cards";
@import "./hero-row";
@import "./intros";
