:root {
  --eaw-badge-bg: var(--eab-gray-d-700);
  --eaw-badge-border-radius: 4px;
  --eaw-badge-color: #fff;
  --eaw-badge-font-size: #{map-get($font-sizes, '14px')};;
  --eaw-badge-padding: 1px 7px;
}

.widget .eaw-badge, // needed to for higher specificity
.widget .widget-html__wysiwyg .fr-view .eaw-badge, // needed to for higher specificity in edit mode
.eaw-badge {
  background-color: var(--eaw-badge-bg);
  border-radius: var(--eaw-badge-border-radius);
  color: var(--eaw-badge-color);
  display: inline-block;
  font-size: var(--eaw-badge-font-size);
  line-height: normal;
  padding: var(--eaw-badge-padding);
}

@each $color, $value in map-sort($badge-colors) {
  $rgb: rgb-values-from-hex($value, '');

  .eaw-badge--#{$color} {
    --eaw-badge-bg: #{$value};
    --eaw-badge-color: #{color-yiq($value)};
  }

  .eaw-badge--#{$color}-light {
    --eaw-badge-bg: rgb(#{$rgb} / 20%);
    --eaw-badge-color: #{color-yiq($value, $value)};
  }
}
