.widget--contact-us--email,
.widget--contact-us--location,
.widget--contact-us--monitor-blue,
.widget--contact-us--operating-hours,
.widget--contact-us--phone,
.widget--contact-us--service-desk,
.widget--contact-us--slack,
.widget--contact-us--support-blue {
  .widget-content {
    --eaw-widget-content-padding-left: 56px;

    position: relative;

    // Using a mask instead of background image to allow it to be colored to the --eaw-body-color
    &:after {
      background-color: var(--eaw-body-color);
      content: "";
      height: 32px;
      left: 0;
      mask-size: auto auto;
      mask-repeat: no-repeat;
      position: absolute;
      top: 0;
      width: 32px;
    }

    #{$widget-html-content-selector} {
      p {
        margin-bottom: map-get($font-sizes, '8px');
      }
    }
  }
}

.widget--contact-us--email {
  .widget-content {
    &:after {
      mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M464 64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM48 96h416c8.8 0 16 7.2 16 16v41.4c-21.9 18.5-53.2 44-150.6 121.3-16.9 13.4-50.2 45.7-73.4 45.3-23.2 .4-56.6-31.9-73.4-45.3C85.2 197.4 53.9 171.9 32 153.4V112c0-8.8 7.2-16 16-16zm416 320H48c-8.8 0-16-7.2-16-16V195c22.8 18.7 58.8 47.6 130.7 104.7 20.5 16.4 56.7 52.5 93.3 52.3 36.4 .3 72.3-35.5 93.3-52.3 71.9-57.1 107.9-86 130.7-104.7v205c0 8.8-7.2 16-16 16z"/></svg>');
    }
  }
}

.widget--contact-us--location {
  .widget-content {
    &:after {
      mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M192 96c-52.9 0-96 43.1-96 96s43.1 96 96 96 96-43.1 96-96-43.1-96-96-96zm0 160c-35.3 0-64-28.7-64-64s28.7-64 64-64 64 28.7 64 64-28.7 64-64 64zm0-256C86 0 0 86 0 192c0 77.4 27 99 172.3 309.7 9.5 13.8 29.9 13.8 39.5 0C357 291 384 269.4 384 192 384 86 298 0 192 0zm0 473.9C52.7 272.5 32 256.5 32 192c0-42.7 16.6-82.9 46.9-113.1S149.3 32 192 32s82.9 16.6 113.1 46.9S352 149.3 352 192c0 64.5-20.7 80.5-160 281.9z"/></svg>');
    }
  }
}

.widget--contact-us--monitor-blue {
  .widget-content {
    &:after {
      mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M528 0H48C21.5 0 0 21.5 0 48v288c0 26.5 21.5 48 48 48h192l-24 96h-72c-8.8 0-16 7.2-16 16s7.2 16 16 16h288c8.8 0 16-7.2 16-16s-7.2-16-16-16h-72l-24-96h192c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM249 480l16-64h46l16 64h-78zm295-144c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16v-48h512v48zm0-80H32V48c0-8.8 7.2-16 16-16h480c8.8 0 16 7.2 16 16v208z"/></svg>');
    }
  }
}

.widget--contact-us--operating-hours {
  .widget-content {
    &:after {
      mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm216 248c0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216zm-148.9 88.3l-81.2-59c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h14c6.6 0 12 5.4 12 12v146.3l70.5 51.3c5.4 3.9 6.5 11.4 2.6 16.8l-8.2 11.3c-3.9 5.3-11.4 6.5-16.8 2.6z"/></svg>');
    }
  }
}

.widget--contact-us--phone {
  .widget-content {
    &:after {
      mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M493.1 351.3L384.7 304.8a31.4 31.4 0 0 0 -36.5 8.9l-44.1 53.9A350 350 0 0 1 144.5 208l53.9-44.1a31.4 31.4 0 0 0 8.9-36.5l-46.5-108.5A31.3 31.3 0 0 0 125 .8L24.2 24.1A31.1 31.1 0 0 0 0 54.5C0 307.8 205.3 512 457.5 512A31.2 31.2 0 0 0 488 487.7L511.2 387a31.2 31.2 0 0 0 -18.1-35.7zM456.9 480C222.4 479.7 32.3 289.7 32.1 55.2l99.6-23 46 107.4-72.8 59.5C153.3 302.3 209.4 358.6 313 407.2l59.5-72.8 107.4 46z"/></svg>');
    }
  }
}

.widget--contact-us--service-desk {
  .widget-content {
    &:after {
      mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M320 352h-4.7c-12.2 0-24 2.9-35.5 6.8a173.8 173.8 0 0 1 -111.6 0c-11.5-3.9-23.3-6.8-35.4-6.8H128A128 128 0 0 0 0 480a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32 128 128 0 0 0 -128-128zM32 480a96.1 96.1 0 0 1 96-96h4.7c6.9 0 14.9 1.6 25.2 5.1a205.8 205.8 0 0 0 132.2 0c10.3-3.5 18.3-5.1 25.2-5.1h4.7a96.1 96.1 0 0 1 96 96zm16-256a16 16 0 0 0 16-16v-16c0-88.2 71.8-160 160-160s160 71.8 160 160v16a80.1 80.1 0 0 1 -80 80h-32a32 32 0 0 0 -32-32h-32a32 32 0 0 0 0 64h96a112.1 112.1 0 0 0 112-112v-16C416 86.1 329.9 0 224 0S32 86.1 32 192v16a16 16 0 0 0 16 16zM224 96a95.6 95.6 0 0 1 71.2 159.8c0 .1 .1 .2 .2 .2H304a47.9 47.9 0 0 0 40.6-22.6C349 220.4 352 206.6 352 192a128 128 0 0 0 -256 0c0 40.4 19.1 76 48.4 99.5-.1-1.2-.4-2.3-.4-3.5a63.3 63.3 0 0 1 8.9-32A95.6 95.6 0 0 1 224 96z"/></svg>');
    }
  }
}

.widget--contact-us--slack {
  .widget-content {
    &:after {
      mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M94.1 315.1c0 25.9-21.2 47.1-47.1 47.1S0 341 0 315.1c0-25.9 21.2-47.1 47.1-47.1h47.1v47.1zm23.7 0c0-25.9 21.2-47.1 47.1-47.1s47.1 21.2 47.1 47.1v117.8c0 25.9-21.2 47.1-47.1 47.1s-47.1-21.2-47.1-47.1V315.1zm47.1-189c-25.9 0-47.1-21.2-47.1-47.1S139 32 164.9 32s47.1 21.2 47.1 47.1v47.1H164.9zm0 23.7c25.9 0 47.1 21.2 47.1 47.1s-21.2 47.1-47.1 47.1H47.1C21.2 244 0 222.8 0 196.9s21.2-47.1 47.1-47.1H164.9zm189 47.1c0-25.9 21.2-47.1 47.1-47.1 25.9 0 47.1 21.2 47.1 47.1s-21.2 47.1-47.1 47.1h-47.1V196.9zm-23.7 0c0 25.9-21.2 47.1-47.1 47.1-25.9 0-47.1-21.2-47.1-47.1V79.1c0-25.9 21.2-47.1 47.1-47.1 25.9 0 47.1 21.2 47.1 47.1V196.9zM283.1 385.9c25.9 0 47.1 21.2 47.1 47.1 0 25.9-21.2 47.1-47.1 47.1-25.9 0-47.1-21.2-47.1-47.1v-47.1h47.1zm0-23.7c-25.9 0-47.1-21.2-47.1-47.1 0-25.9 21.2-47.1 47.1-47.1h117.8c25.9 0 47.1 21.2 47.1 47.1 0 25.9-21.2 47.1-47.1 47.1H283.1z"/></svg>');
    }
  }
}

.widget--contact-us--support-blue {
  .widget-content {
    &:after {
      mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M424 160H152c-13.3 0-24 10.7-24 24v144c0 13.3 10.7 24 24 24h272c13.3 0 24-10.7 24-24V184c0-13.3-10.7-24-24-24zm-8 160H160V192h256v128zm128-96h32V112c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v112h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H0v112c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V288h-32c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 96v80c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16v-80c35.3 0 64-28.7 64-64s-28.7-64-64-64v-80c0-8.8 7.2-16 16-16h480c8.8 0 16 7.2 16 16v80c-35.3 0-64 28.7-64 64s28.7 64 64 64z"/></svg>');
    }
  }
}
