.app-content--edit-mode {
  .header--reworked-ui,
  eaw-navigation-menu {
    /*
      Disable the mouse pointer events when the page is in edit mode.
      We don't want the user accidentally clicking something in the header and navigating away from the page.
    */
    pointer-events: none;
  }
}

// Because of other style tweaks, we don't need the header to be moved when the site has a sidebar open
.app-content--is-pushed .header-top {
  left: 0 !important;
}

// Hide some of the out of the box lumapps header elements
.header-content,
.header-main-nav,
.header-top__bookmarked-apps,
.header-top__contribution,
.header-top__directory-favorites,
.header-top__logo,
.header-top__mobile-side-nav,
.header-top__play-video,
.header-top__resource-center,
.header-top__search,
.header-top__user-settings {
  display: none;
}

.header--reworked-ui {
  --eaw-header-background-top: calc(var(--eaw-navbar-height) + var(--eaw-header-top-bar-height));

  // A margin bottom can get set depending on the "header" settings that can be set in LumApps
  margin-bottom: 0 !important; //needs to be important to override inline styles applied by LumApps

  .header-top {
    background-color: var(--eaw-header-top-bar-bg, #000);
    color: var(--eaw-header-top-bar-color, #fff);
    // box-shadow: none;
    height: var(--eaw-header-top-bar-height);
    padding: 0 var(--eaw-header-top-padding-x);
    position: relative !important;
  }

  .header-top__wrapper {
    height: var(--eaw-header-top-bar-height);
    margin: 0;
    max-width: 100%;
    width: 100%;
  }

  .header-top__actions {
    .lumx-button {
      background-color: var(--eaw-header-top-bar-button-bg, transparent);
      color: var(--eaw-header-top-bar-button-color, #fff);
      transition: background-color .25s, scale .25s, transform .25s;

      &[class*="--is-active"],
      &:hover {
        background-color: var(--eaw-header-top-bar-button-hover-bg, rgb(255 255 255 / 25%));
        color: var(--eaw-header-top-bar-button-hover-color, #fff);
        transform: scale(1.2);
      }
    }
  }
}

.eaw-navigation-menu--parent {
  z-index: 10;
}

.eaw-navigation-menu--site {
  z-index: 5;
}
