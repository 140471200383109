

#enterprise-search-results-page {
  &:not(.app-content--edit-mode) {
    --eaw-hero-row-height: 0px;

    .content-details .component-row--is-root:first-child,
    .row[data-id=layout-row0] {
      display: none;
    }
  }

  // Hide the search button in the header since the home page already has its own search box
  .eaw-header-btn-search {
    display: none;
  }
}
