:root {
  --eaw-widget-bg: transparent;
  --eaw-widget-border-color: unset;
  --eaw-widget-border-style: solid;
  --eaw-widget-border-width: 0;
  --eaw-widget-breadcrumb-color: #{$widget-breadcrumb-color};
  --eaw-widget-color: var(--eaw-body-color);
  --eaw-widget-color-rgb: var(--eaw-body-color-rgb);
  --eaw-widget-color-dark: var(--eaw-body-color-dark);
  --eaw-widget-color-dark-rgb: var(--eaw-body-color-dark-rgb);
  --eaw-widget-color-light: var(--eaw-body-color-light);
  --eaw-widget-color-light-rgb: var(--eaw-body-color-light-rgb);
  --eaw-widget-color-secondary: rgb(var(--eaw-widget-color-rgb) / 75%);
  --eaw-widget-color-tertiary: rgb(var(--eaw-widget-color-rgb) / 50%);
  --eaw-widget-error-color: #{$widget-error-color};
  --eaw-widget-footer-link-font-size: #{map-get($font-sizes, '14px')};
  --eaw-widget-font-family: #{$widget-font-family};
  --eaw-widget-font-size: #{$widget-font-size};
  --eaw-widget-heading-color: var(--eaw-headings-color);
  --eaw-widget-heading-color-rgb: var(--eaw-heading-color-rgb);
  --eaw-widget-heading-color-dark: var(--eaw-heading-color-dark);
  --eaw-widget-heading-color-dark-rgb: var(--eaw-heading-color-dark-rgb);
  --eaw-widget-heading-color-light: var(--eaw-heading-color-light);
  --eaw-widget-heading-color-light-rgb: var(--eaw-heading-color-light-rgb);
  --eaw-widget-header-color: var(var(--eaw-widget-color));
  --eaw-widget-header-color-dark: var(--eaw-widget-color-dark);
  --eaw-widget-header-color-light: var(--eaw-widget-color-light);
  --eaw-widget-header-font-size: #{map-get($font-sizes, '16px')};
  --eaw-widget-header-letter-spacing: var(--eaw-headings-letter-spacing);
  --eaw-widget-header-line-height: 1.5;
  --eaw-widget-letter-spacing: var(--eaw-letter-spacing);
  --eaw-widget-line-height: var(--eaw-line-height);
  --eaw-widget-quick-links-border: #{$widget-quick-links-border};
  --eaw-widget-table-bg: transparent;
  --eaw-widget-table-alt-bg: rgb(220 220 220 / 50%);
  --eaw-widget-table-border-color: #e0e0e0;
  --eaw-widget-table-head-bg: #{$widget-table-head-bg};
  --eaw-widget-table-head-color: #{$widget-table-head-color};
  --eaw-widget-table-padding: .5rem;
  --eaw-widget-title-font-size: #{$widget-title-font-size};
  --eaw-widget-title-line-height: #{$widget-title-line-height};
  --eaw-widget-vertical-spacing: 24px;
}

@include media-breakpoint-up('lg') {
  :root {
    --eaw-widget-vertical-spacing: 0;
  }
}

.component-widget {
  margin: 0;
}

.content-details,
.layout {
  .widget:not(.widget--has-ungrouped-container-block) {
    background-color: var(--eaw-widget-bg);
    border-bottom-color: var(--eaw-widget-border-bottom-color, var(--eaw-widget-border-color));
    border-bottom-style: var(--eaw-widget-border-bottom-style, var(--eaw-widget-border-style));
    border-bottom-width: var(--eaw-widget-border-bottom-width, var(--eaw-widget-border-width));
    border-left-color: var(--eaw-widget-border-left-color, var(--eaw-widget-border-color));
    border-left-style: var(--eaw-widget-border-left-style, var(--eaw-widget-border-style));
    border-left-width: var(--eaw-widget-border-left-width, var(--eaw-widget-border-width));
    border-right-color: var(--eaw-widget-border-right-color, var(--eaw-widget-border-color));
    border-right-style: var(--eaw-widget-border-right-style, var(--eaw-widget-border-style));
    border-right-width: var(--eaw-widget-border-right-width, var(--eaw-widget-border-width));
    border-top-color: var(--eaw-widget-border-top-color, var(--eaw-widget-border-color));
    border-top-style: var(--eaw-widget-border-top-style, var(--eaw-widget-border-style));
    border-top-width: var(--eaw-widget-border-top-width, var(--eaw-widget-border-width));
    border-radius: var(--eaw-widget-border-radius, 0);
    box-shadow: var(--eaw-widget-shadow, none);
    margin: 0 0 var(--eaw-widget-vertical-spacing) 0;
    overflow: var(--eaw-widget-overflow);
    width: 100%;
  }
}

.widget {
  &.widget--has-header {
    --eaw-widget-content-padding-top: 1rem;
  }

  &.widget--has-footer {
    --eaw-widget-content-padding-bottom: 1rem;
  }

  .widget-content {
    background-color: var(--eaw-widget-content-bg);
    color: var(--eaw-widget-color);
    font-family: var(--eaw-widget-font-family);
    font-size: var(--eaw-widget-font-size);
    letter-spacing: var(--eaw-widget-letter-spacing);
    line-height: var(--eaw-widget-line-height);

    a {
      color: var(--eaw-link-color);
      text-decoration: var(--eaw-link-text-decoration);

      &:hover {
        color: var(--eaw-link-color-hover);
      }

      &.eaw-icon--arrow-right {
        padding-right: 26px;
        position: relative;

        &:hover {
          &:after {
            text-decoration: none;
            transform: translate(6px, -50%);
          }
        }

        &:after {
          @include fa-icon-light('arrow-right', 'absolute');
          right: 6px;
          text-decoration: none;
          transition: transform 250ms ease-in-out;
        }
      }

      &.banner-link {
        color: inherit;
      }
    }
  }

  .widget-content,
  .widget-content--v2:not(.widget-content--block-variant-ungrouped):not(.widget-content--block-variant-side):not(.widget-content--block-variant-cover) {
    overflow: var(--eaw-widget-content-overflow);
    padding-top: var(--eaw-widget-content-padding-top, var(--eaw-widget-content-padding-y, 0));
    padding-right: var(--eaw-widget-content-padding-right, var(--eaw-widget-content-padding-x, 0));
    padding-bottom: var(--eaw-widget-content-padding-bottom, var(--eaw-widget-content-padding-y, 0));
    padding-left: var(--eaw-widget-content-padding-left, var(--eaw-widget-content-padding-x, 0));
  }

  .widget-header__wrapper--clickable {
    color: inherit;
  }
}

.widget--eaw-overflow-visible {
  overflow: visible;

  .widget-content {
    overflow: visible;
  }
}

.widget-footer {
  background-color: var(--eaw-widget-footer-bg);

  .widget-footer__link,
  .widget-wrapper {
    --lumx-typography-interface-subtitle1-font-weight: normal;

    color: var(--eaw-link-color);
  }

  .widget-footer__link,
  .widget-footer__label {
    font-size: var(--eaw-widget-footer-link-font-size);
    line-height: var(--eaw-widget-footer-line-height);
  }

  .widget-wrapper {
    border-color: var(--eaw-widget-table-border-color);
    margin: 0;
    padding: map-get($font-sizes, '12px') map-get($font-sizes, '16px');
  }
}

.widget-footer__icon {
  transition: transform 250ms ease-in-out;
}

.widget-footer__wrapper--layout-left {
  &:hover .widget-footer__icon {
    transform: translateX(-5px);
  }
}

.widget-footer__wrapper--layout-right {
  &:hover .widget-footer__icon {
    transform: translateX(5px);
  }
}

.widget-header {
  background-color: var(--eaw-widget-header-bg);
  color: var(--eaw-widget-header-color);

  .widget-wrapper {
    border-color: var(--eaw-widget-table-border-color);
    margin: 0;
  }

  .widget-header__icon {
    color: inherit;
    font-size: var(--eaw-widget-header-font-size);
  }

  .widget-header__title {
    font-size: var(--eaw-widget-header-font-size);
    font-weight: var(--eaw-widget-header-font-weight, normal);
    line-height: var(--eaw-widget-header-line-height);
    letter-spacing: var(--eaw-widget-header-letter-spacing);
  }
}

// Needs the extra selectors for higher specificity to override lumapps styles
.widget-title .widget-content {
  .block-title,
  .widget-title__input,
  .widget-title__title {
    color: var(--eaw-widget-color);
    font-family: var(--eaw-headings-font-family);
    font-size: var(--eaw-heading1-font-size);
    font-weight: var(--eaw-headings-font-weight);
    letter-spacing: var(--eaw-headings-letter-spacing);
    line-height: var(--eaw-heading1-line-height);
  }
}

.widget--bg-primary {
  --eaw-widget-bg: var(--eaw-body-bg-primary);
}

.widget--bg-secondary {
  --eaw-widget-bg: var(--eaw-body-bg-secondary);
}

.widget--bg-tertiary {
  --eaw-widget-bg: var(--eaw-body-bg-tertiary);
}

.widget--is-full-height {
  .widget-content--v2 .block-html {
    flex-grow: 1;
  }

  &.widget-html {
    .widget-wrapper,
    .widget-transclude,
    .fr-element {
      height: 100%;
    }
  }
}

@import './contact-us';
@import './widget-content-list';
@import './widget-html';
@import './widget-rich-text';
@import './badges';
@import './flex';
@import './help-us-improve';
@import './quick-links';
@import './sticky-page-navbar';
@import './background-gradient';
@import './ea-brand-backgrounds';
@import './lumapps-overrides';
