@use "sass:color";

// TODO: Explore responsive fonts

:root {
  @include themed-vars($theme-colors);
  --eaw-backdrop-bg: rgb(0 0 0 / 50%);
  --eaw-body-bg: #9098A6;
  --eaw-body-bg-secondary: var(--eab-gray-d-200);
  --eaw-body-bg-tertiary: var(--eab-gray-d-100);
  --eaw-body-color: #{$body-color};
  --eaw-body-color-rgb: #{$body-color-rgb};
  --eaw-body-color-dark: #{$body-color-dark};
  --eaw-body-color-dark-rgb: #{$body-color-dark-rgb};
  --eaw-body-color-light: #{$body-color-light};
  --eaw-body-color-light-rgb: #{$body-color-light-rgb};
  --eaw-body-color-secondary: rgb(var(--eaw-body-color-rgb) / 75%);
  --eaw-body-color-tertiary: rgb(var(--eaw-body-color-rgb) / 50%);
  --eaw-body-font-family: #{$body-font};
  --eaw-font-size: #{map-get($font-sizes, '16px')};
  --eaw-line-height: 1.5;
  --eaw-letter-spacing: normal;
  --eaw-headings-color: #{$headings-color};
  --eaw-headings-color-rgb: #{$body-color-rgb};
  --eaw-headings-color-dark: #{$headings-color-dark};
  --eaw-headings-color-dark-rgb: #{$headings-color-dark-rgb};
  --eaw-headings-color-light: #{$headings-color-light};
  --eaw-headings-color-light-rgb: #{$headings-color-light-rgb};
  --eaw-headings-font-family: #{$headings-font};
  --eaw-headings-font-weight: bold;
  --eaw-headings-letter-spacing: 1px;
  --eaw-heading1-font-size: #{map-get($font-sizes, '42px')};
  --eaw-heading1-line-height: #{map-get($font-sizes, '52px')};
  --eaw-heading2-font-size: #{map-get($font-sizes, '32px')};
  --eaw-heading2-line-height: #{map-get($font-sizes, '42px')};
  --eaw-heading3-font-size: #{map-get($font-sizes, '24px')};
  --eaw-heading3-line-height: #{map-get($font-sizes, '32px')};
  --eaw-heading4-font-size: #{map-get($font-sizes, '20px')};
  --eaw-heading4-line-height: #{map-get($font-sizes, '28px')};
  --eaw-heading5-font-size: #{map-get($font-sizes, '18px')};
  --eaw-heading5-line-height: #{map-get($font-sizes, '24px')};
  --eaw-heading6-font-size: #{map-get($font-sizes, '16px')};
  --eaw-heading6-line-height: #{map-get($font-sizes, '24px')};
  --eaw-button-color: #fff;
  --eaw-button-bg: #000;
  --eaw-button-bg-rgb: 0 0 0;
  --eaw-content-first-row-color: #{$content-first-row-color};
  --eaw-content-first-row-line-clamp: 3;
  --eaw-content-first-row-text-align: center;
  --eaw-content-row-spacing: 24px;
  --eaw-dropdown-border-radius: 6px;
  --eaw-dropdown-shadow: 0px 4px 8px rgb(0 0 0 / 15%);
  --eaw-flex-grid-gutter-x: 1.5rem;
  --eaw-flex-grid-gutter-y: 1.5rem;
  --eaw-hero-row-height: 225px;
  --eaw-navbar-height: 56px;
  --eaw-header-background-position: 50 % 50%;
  --eaw-header-background-repeat: no-repeat;
  --eaw-header-background-size: cover;
  --eaw-header-overlay-background: none;
  --eaw-header-overlay-background-position: 50% 50%;
  --eaw-header-overlay-background-repeat: no-repeat;
  --eaw-header-overlay-background-size: cover;
  --eaw-header-overlay-opacity: 0.09;
  --eaw-header-shadow: #{$header-shadow};
  --eaw-header-top-padding-x: 24px;
  --eaw-header-top-bar-height: 44px;
  --eaw-link-color: #{$link-color};
  --eaw-link-color-rgb: #{rgb-values-from-hex($link-color, '')};
  --eaw-link-color-hover: #{$link-color-hover};
  --eaw-link-color-dark: #{$link-color-dark};
  --eaw-link-color-dark-hover: #{$link-color-dark-hover};
  --eaw-link-color-light: #{$link-color-light};
  --eaw-link-color-light-hover: #{$link-color-light-hover};
  --eaw-link-text-decoration: #{$link-text-decoration};
  --eaw-link-text-decoration-hover: #{$link-text-decoration-hover};
  --eaw-border-color: rgb(0 0 0 / 15%);
  --eaw-border-radius: #{$border-radius};
  --eaw-color-contrast-dark: #{$color-contrast-dark};
  --eaw-color-contrast-light: #{$color-contrast-light};
  --eaw-calendar-event-color: #{$calendar-event-color};
  --eaw-calendar-event-color-rgb: #{to-rgb($calendar-event-color)};
  --eaw-calendar-event-company-color: #{$calendar-event-company-color};
  --eaw-calendar-event-company-color-rgb: #{to-rgb($calendar-event-company-color)};
  --eaw-calendar-event-holiday-color: #{$calendar-event-holiday-color};
  --eaw-calendar-event-holiday-color-rgb: #{to-rgb($calendar-event-holiday-color)};
  --eaw-calendar-event-location-color: #{$calendar-event-location-color};
  --eaw-calendar-event-location-color-rgb: #{to-rgb($calendar-event-location-color)};
  --eaw-input-bg: var(--eaw-body-bg);
  --eaw-input-border-color: rgb(var(--eaw-body-color-rgb) / 25%);
  --eaw-input-border-radius: 0.375rem;
  --eaw-input-border-width: 1px;
  --eaw-input-color: var(--eaw-body-color);
  --eaw-modal-bg: var(--eaw-body-bg);
  --eaw-modal-color: var(--eaw-body-color);
  --eaw-modal-border-color: var(--eaw-body-bg-secondary);
  --eaw-modal-border-radius: .5rem;
  --eaw-modal-border-width: 1px;
  --eaw-modal-shadow-color: rgb(0 0 0 / 15%);
  --eaw-modal-shadow: 0 0.5rem 1rem var(--eaw-modal-shadow-color);
  --eaw-popover-arrow-size: 10px;
  --eaw-popover-bg: var(--eaw-body-bg);
  --eaw-popover-color: var(--eaw-body-color);
  --eaw-popover-border-color: rgb(var(--eaw-body-color-rgb) / 25%);
  --eaw-popover-border-radius: .5rem;
  --eaw-popover-border-width: 1px;
  --eaw-popover-shadow-color: rgb(0 0 0 / 15%);
  --eaw-popover-shadow: 0 0.5rem 1rem var(--eaw-popover-shadow-color);
  --eaw-notification-bg: #fff;
  --eaw-pager-bullet-color: var(--eab-gray-d);
  --eaw-pager-bullet-hover-color: var(--eab-gray-b);
  --eaw-pager-bullet-active-color: var(--eab-gray-b);

  @each $color, $value in map-sort($ea-brand-colors) {
    $rgb: rgb-values-from-hex($value, '');

    --eab-#{$color}: #{$value};
    --eab-#{$color}-rgb: #{$rgb};
    --eab-#{$color}-100: #{tint-color($value, 80%)};
    --eab-#{$color}-200: #{tint-color($value, 60%)};
    --eab-#{$color}-300: #{tint-color($value, 40%)};
    --eab-#{$color}-400: #{tint-color($value, 20%)};
    --eab-#{$color}-500: #{$value};
    --eab-#{$color}-600: #{shade-color($value, 20%)};
    --eab-#{$color}-700: #{shade-color($value, 40%)};
    --eab-#{$color}-800: #{shade-color($value, 60%)};
    --eab-#{$color}-900: #{shade-color($value, 80%)};
  }

  --lumx-app-background: var(--eaw-site-bg);
  --lumx-widget-header-font-family: var(--eaw-headings-font-family);
}

html[data-eaw-theme=light] {
  --eaw-body-bg: #fff;
}

@include media-breakpoint-up('lg') {
  :root {
    --eaw-content-row-spacing: 64px;
    --eaw-header-top-padding-x: 48px;
    --eaw-heading1-font-size: #{map-get($font-sizes, '52px')};
    --eaw-heading1-line-height: #{map-get($font-sizes, '64px')};
    --eaw-heading2-font-size: #{map-get($font-sizes, '42px')};
    --eaw-heading2-line-height: #{map-get($font-sizes, '52px')};
    --eaw-heading3-font-size: #{map-get($font-sizes, '32px')};
    --eaw-heading3-line-height: #{map-get($font-sizes, '42px')};
    --eaw-heading4-font-size: #{map-get($font-sizes, '24px')};
    --eaw-heading4-line-height: #{map-get($font-sizes, '32px')};
    --eaw-heading5-font-size: #{map-get($font-sizes, '18px')};
    --eaw-heading5-line-height: #{map-get($font-sizes, '24px')};
    --eaw-heading6-font-size: #{map-get($font-sizes, '16px')};
    --eaw-heading6-line-height: #{map-get($font-sizes, '24px')};
    --eaw-widget-vertical-spacing: 0;
  }
}
