.component-row--intro-mission--icons {
  background-color: #f6f9fc;

  .widget-html .widget-content {
    background-position: 50% 24px !important; // Must be important because publishers can use the LumApps tools to set a background-image position
    background-repeat: no-repeat;
    background-size: 72px 76px !important; // Must be important because publishers can use the LumApps tools to set a background-size (fill, cover)
    text-align: center;

    h4 {
      font-size: var(--eaw-heading5-font-size);
      line-height: var(--eaw-heading5-line-height);
    }
  }

  #{$widget-html-content-selector} {
    padding: 116px 24px 24px;
  }
}

.component-row--intro-slideshow--cta-links {
  // The media list cell should be set as a 9 width column
  .component-cell--w-9 {
    @media screen and (min-width: 1024px) {
      flex: 0 0 calc(100% - 216px);
      max-width: calc(100% - 216px);
    }

    .component-widget {
      background-color: #fff;
      border: var(--eaw-card-border-width) solid var(--eaw-card-border-color);
      border-radius: var(--eaw-card-border-radius);
      box-shadow: var(--eaw-card-shadow);
    }

    .widget-content {
      border-radius: var(--eaw-card-border-radius);
    }
  }

  // The cta links cell should be set as a 3 width column
  .component-cell--w-3 {
    @media screen and (min-width: 1024px) {
      flex: 0 0 216px;
      max-width: 216px;
    }

    .component-cell__component {
      background-color: #fff;
      border: var(--eaw-card-border-width) solid var(--eaw-card-border-color);
      border-radius: var(--eaw-card-border-radius);
      box-shadow: var(--eaw-card-shadow);
      flex: 1 0 auto;
      margin-top: 14px;
      transition: box-shadow 250ms ease-in-out, transform 250ms ease-in-out;

      &:first-child {
        margin-top: 0;
      }

      &:hover {
        box-shadow: var(--eaw-card-cta-hover-shadow);

        // Don't add the transform when we are in edit mode.
        .content:not(.content--designer-mode) & {
          transform: translate3d(0, var(--eaw-card-cta-hover-shift-y), 0);
        }
      }
    }

    .component-widget {
      margin-bottom: 0;
    }

    .widget-html .widget-content {
      background-color: var(--eaw-card-bg);
      background-position: 50% 20px !important; // Must be important because publishers can use the LumApps tools to set a background-image position
      background-repeat: no-repeat;
      background-size: 32px 32px !important; // Must be important because publishers can use the LumApps tools to set a background-size (fill, cover)
      border-radius: var(--eaw-card-border-radius);
      position: relative;
      text-align: center;

      a {
        &:hover {
          text-decoration: none;
        }
      }

      // Don't have the CTA box fully clickable when we are in edit mode as it would prevent the publisher from editing the html widget content
      .content:not(.content--designer-mode) & {
        a {
          // This is in order to make the CTA box completely clickable
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
          }
        }
      }
    }

    #{$widget-html-content-selector} {
      padding: 60px 20px 20px 20px;
    }
  }

  // NGI styles
  // The media list cell should be set as a 9 width column
  .cell--w-9 {
    @media screen and (min-width: 1024px) {
      flex: 0 0 calc(100% - 216px);
      max-width: calc(100% - 216px);
    }

    .widget {
      background-color: var(--eaw-card-bg);
      border: var(--eaw-card-border-width) solid var(--eaw-card-border-color);
      border-radius: var(--eaw-card-border-radius);
      box-shadow: var(--eaw-card-shadow);
    }

    .widget-image-gallery {
      .lumx-thumbnail__image {
        border-top-left-radius: var(--eaw-card-border-radius);
        border-top-right-radius: var(--eaw-card-border-radius);
      }

    }
  }

  .cell--w-3 {
    @media screen and (min-width: 1024px) {
      flex: 0 0 216px;
      max-width: 216px;
    }

    .cell__wrapper {
      row-gap: 1rem;
    }

    .widget {
      background-color: var(--eaw-card-bg);
      border: var(--eaw-card-border-width) solid var(--eaw-card-border-color);
      border-radius: var(--eaw-card-border-radius);
      box-shadow: var(--eaw-card-shadow);
      transition: box-shadow 250ms ease-in-out, transform 250ms ease-in-out;

      &:hover {
        box-shadow: var(--eaw-card-cta-hover-shadow);
        transform: translate3d(0, var(--eaw-card-cta-hover-shift-y), 0);
      }
    }

    .widget-content {
      --eaw-widget-content-padding-bottom: 20px;
      --eaw-widget-content-padding-left: 20px;
      --eaw-widget-content-padding-right: 20px;
      --eaw-widget-content-padding-top: 60px;

      background-position: 50% 20px !important; // Must be important because publishers can use the LumApps tools to set a background-image position
      background-repeat: no-repeat;
      background-size: 32px 32px !important; // Must be important because publishers can use the LumApps tools to set a background-size (fill, cover)
      border-radius: var(--eaw-card-border-radius);
      position: relative;
      text-align: center;
    }

    .block-html {
      a {
        // This is in order to make the CTA box completely clickable
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
        }
      }
    }
  }
}

.component-row--col-four-eight,
.component-row--intro-page,
.component-row--intro-page--grey {
  &#location-resources-image {
    .component-cell--w-8 {
      .widget-featured-image__image {
        height: inherit !important;

        @media (max-width: 1024px) {
          height: 300px !important;
          position: relative;
        }
      }
    }
  }

  .component-row__wrapper {
    .component-cell {
      .component-cell__component .component-widget .widget {
        &.widget-content-list--view-mode-cover {
          &.widget-content-list--background-thumbnail {
            // 464px seems to be when LumApps actually applies the content over the background, anything smaller stacks it
            @media (min-width: 464px) {
              .content-block-publication-date,
              .user-block__primary-field {
                color: #fff;
              }

              .like-button--like {
                .like-button__btn {
                  i.mdi-heart {
                    &:before {
                      color: #fff;
                    }
                  }
                }
              }

              .like-button__btn:hover {
                i.mdi-heart {
                  &:before {
                    color: #fff;
                  }
                }
              }
            }
          }

          .widget-slideshow__block {
            min-height: 400px;
          }
        }

        &.widget-content-list--view-mode-horizontal {
          background-color: #fff;
          border: var(--eaw-card-border-width) solid var(--eaw-card-border-color);
          border-radius: var(--eaw-card-border-radius);
          box-shadow: var(--eaw-card-shadow);

          .widget-slideshow,
          .widget-slideshow__block {
            padding: 0;
          }

          .content-block__fields {
            margin-left: 24px;
            margin-right: 24px;

            .content-block-title {
              font-size: var(--eaw-heading5-font-size);
              font-weight: bold;
              line-height: var(--eaw-heading5-line-height);
              margin-bottom: 24px;
            }
          }

          .content-block__thumbnail {
            margin-bottom: 18px;
            min-height: 330px;
          }
        }

        &.widget-featured-image {
          .widget-content {
            min-height: 400px;

            .widget-featured-image__image {
              min-height: 400px;
            }
          }
        }

        &.widget-html .widget-content {
          h1, h2, h3, h4, h5, h6 {
            font-size: var(--eaw-heading3-font-size);
            line-height: var(--eaw-heading3-line-height);
            margin-bottom: map-get($font-sizes, '16px');
          }
        }

        &.widget-media-list {
          .widget-slideshow,
          .widget-slideshow__block {
            min-height: 400px;
            padding: 0;
          }

          .widget-slideshow.widget-slideshow--controls {
            border: var(--eaw-card-border-width) solid var(--eaw-card-border-color);
            border-radius: var(--eaw-card-border-radius);
            box-shadow: var(--eaw-card-shadow);
            min-height: 360px; // Leave room for the controls at the bottom of the slideshow

            .widget-slideshow__block {
              min-height: 360px;
            }
          }
        }
      }
    }
  }
}

// There are more selectors in the below styles than would normally be necessary, just to force a higher specificity
.component-row--intro-page--grey {
  .component-row__wrapper {
    background-color: var(--eaw-card-gray-bg);

    .component-cell {
      &:first-child {
        .component-cell__component .component-widget .widget-html .widget-content {
          padding-left: 24px;
        }
      }

      &:last-child {
        .component-cell__component .component-widget .widget-html .widget-content {
          padding-right: 24px;
        }
      }

      .component-cell__component .component-widget .widget {
        &.widget-content-list {
          background-color: transparent;
          border: none;
          border-radius: 0;
          box-shadow: none;

          @media (min-width: 1024px) {
            .content-block__fields {
              margin-left: 0;
              margin-right: 0;
            }
          }
        }

        &.widget-html .widget-content {
          padding-bottom: 24px;
          padding-top: 24px;

          @media (max-width: 1023px) {
            padding: 24px;
          }
        }

        &.widget-media-list .widget-slideshow.widget-slideshow--controls {
          background-color: transparent;
          border: none;
          border-radius: 0;
          box-shadow: none;
        }
      }
    }
  }
}
