.component-row--quick-links {
  --eaw-widget-font-size: #{map-get($font-sizes, '18px')};
  --eaw-widget-line-height: #{map-get($font-sizes, '28px')};

  .cell__wrapper,
  .component-cell__components {
    padding-left: 16px;
    padding-right: 16px;
  }

  .block-html,
  .widget-html__content {
    p {
      margin-bottom: 16px;

      @media (min-width: 1024px) {
        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        border-bottom: var(--eaw-widget-quick-links-border, #{$widget-quick-links-border});
        border-bottom-color: rgb(var(--eaw-widget-color-rgb) / 50%);
        display: block;
        padding-bottom: 12px;
        padding-right: 36px;
        padding-top: 12px;
        position: relative;
        text-decoration: none;
        transition: border 250ms ease-in-out, color 250ms ease-in-out, opacity 250ms ease-in-out;

        &:hover {
          border-bottom-color: var(--eaw-link-color-hover);

          &:after {
            color: var(--eaw-link-color-hover);
          }
        }

        &:after {
          color: rgb(var(--eaw-widget-color-rgb) / 50%);
          @include fa-icon-light('arrow-right', 'absolute');
          font-size: 1.5rem;
          right: 0;
          transition: color 250ms ease-in-out;
          will-change: color;
        }
      }
    }
  }
}
