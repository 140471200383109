// A utility class that can be used to hide content in display mode, but display it in edit mode
.component-row--designer-mode-content,
.ea-designer-mode-content {
  background-color: var(--eab-mustard-100);
  border: 1px solid var(--eab-mustard-600);
  display: none;
  padding: 1.5rem;

  .content--designer-mode & {
    display: block;
  }
}

.ea-designer-mode-content {
  color: var(--eab-mustard-600);
}

.component-row--designer-mode-content {
  .content--designer-mode & {
    padding: 1.5rem 0 0 0;

    &:after {
      color: var(--eab-mustard-600);
      content: 'Note: This row is only seen when the page is in edit mode.';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

// .app-content--designer-mode {
//   // When using the html editor in edit mode, it adds the `component-cell--hover-disabled` class to the cell of the selected component.
//   // We can use this to hide the cell actions (add new row, widget, column, etc)
//   .component-cell--is-focused.component-cell--hover-disabled .component-cell__actions {
//     display: none;
//   }

//   // In the header row, when we are in edit mode, set the heading color so it can be viewed in the html editor
//   .component-row--is-root:first-child .widget-html__wysiwyg .fr-view {
//     --eaw-widget-heading-color: #000;
//   }
// }

// .content--designer-mode .component-row.component-row--is-root.component-row--is-active:before,
// .content--designer-mode .component-cell.component-cell--is-active:before,
// .content--designer-mode .component-widget.component-widget--is-active .widget:before {
//   border-color: $widget-active-border-color;
// }

// .content--designer-mode .component-row.component-row--is-root.component-row--is-focused:before,
// .content--designer-mode .component-cell.component-cell--is-focused:before,
// .content--designer-mode .component-widget.component-widget--is-focused .widget:before {
//   border-color: $widget-focused-border-color;
// }

// .content--designer-mode .component-cell.component-cell--is-active,
// .content--designer-mode .component-cell.component-cell--is-focused,
// .content--designer-mode .component-row.component-row--is-root.component-row--is-active,
// .content--designer-mode .component-row.component-row--is-root.component-row--is-focused {
//   background-color: var(--eaw-background-active-color, transparent);
// }

// .content--designer-mode .component-row.component-row--is-root:hover,
// .content--designer-mode .component-cell:hover {
//   background-color: var(--eaw-background-active-color, transparent);

//   &:after {
//     background-color: rgba(0, 0, 0, .12);
//     content: "";
//     position: absolute;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     pointer-events: none;
//   }
// }
