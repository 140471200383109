@use "sass:math";

@import "mixins/breakpoints";
@import "mixins/fa-icon";
@import "mixins/meta-ui";
@import "mixins/multi-line-ellipsis";
@import "mixins/nova-ui";

@mixin themed-vars($themeColors) {
  @include css-vars((
    header-background: linear-gradient(to right, map-get($themeColors, 'start') 0%, map-get($themeColors, 'middle') 50%, map-get($themeColors, 'end') 100%),
    widget-color-themed: map-get-default($themeColors, 'widget-color-themed', $widget-color-themed),
    widget-table-alt-bg: rgba(map-get($themeColors, 'middle'), .1),
    widget-table-head-bg: map-get($themeColors, 'middle'),
    widget-table-head-bg-rgb: rgb-values-from-hex(map-get($themeColors, 'middle'), ''),
    widget-table-head-color: color-yiq(map-get($themeColors, 'middle'), #fff, $body-color),
    widget-quick-links-border-color-themed: map-get-default($themeColors, 'widget-quick-links-border-color-themed', $widget-quick-links-border-color-themed),
    theme-color-start: map-get($themeColors, 'start'),
    theme-color-middle: map-get($themeColors, 'middle'),
    theme-color-end: map-get($themeColors, 'end')
  ));
}

@mixin css-vars($css_variables, $prefix: eaw) {
  // :root {
      @each $name, $value in $css_variables {
          --#{$prefix}-#{$name}: #{$value};
      }
  // }
}

@function get-aspect-ratio($width, $height) {
  @return math.div($height, $width) * 100%;
}

@mixin make-containers($breakpoints: $grid-breakpoints, $containers: $container-max-widths) {
  @each $name, $size in $containers {
    @media screen and (min-width: map-get($breakpoints, $name)) {
      .component-row--is-root > .component-row__wrapper--is-fixed {
        width: $size;
      }
    }
  }
}

@mixin knockout-text-shared {
  h1, h2, h3, h4, h5, h6 {
    background-color: #fff;
    color: #000;
    flex-grow: 1;
    margin: 0;
    mix-blend-mode: screen;
  }

  @media screen and (min-width: map-get($grid-breakpoints, lg)) {
    h1 {
      font-size: 6rem;
    }

    h2 {
      font-size: 5rem;
    }

    h3 {
      font-size: 4rem;
    }

    h4 {
      font-size: 3rem;
    }

    h5 {
      font-size: 2rem;
    }

    h6 {
      font-size: 1rem;
    }
  }
}
