// LumApps NGI
// This is here in order to prevent the hero row from popping into place as the site loads due to the negative margin-top on the .layout
#front-office-app {
  position: relative;

  // #maincontent {
  //   padding-top: calc(var(--eaw-hero-row-height) + var(--eaw-navbar-height));
  // }

  .eaw-navigations {
    position: absolute;
    left: 0;
    right: 0;
    top: var(--eaw-header-top-bar-height);
  }
}

// LumApps Legacy Design
// This is here in order to prevent the hero row from popping into place as the site loads due to the negative margin-top on the content-details
#app-view {
  #maincontent {
    // padding-top: calc(var(--eaw-hero-row-height) + var(--eaw-navbar-height));
    position: relative;
  }

  .eaw-navigations {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }
}

.content--type-custom,
.content--type-news,
.content--type-page,
.content--type-template,
.content-page .layout {
  margin-top: calc(var(--eaw-hero-row-height) * -1) !important;
  padding-top: calc(var(--eaw-hero-row-height) + var(--eaw-navbar-height));
  position: relative;
}

// Because of the differences between the styles in LumApps for interface v1 and v2, the header background has to be applied to different elements to display correctly
.content--type-custom .component-row--is-root:first-child .component-row__wrapper,
.content--type-news .component-row--is-root:first-child .component-row__wrapper,
.content--type-page .component-row--is-root:first-child .component-row__wrapper,
.content--type-template .component-row--is-root:first-child .component-row__wrapper,
.content-page .row[data-id=layout-row0] {
  // This is for a secondary background image/overlay
  &:before {
    background: var(--eaw-header-overlay-background);
    background-position: var(--eaw-header-overlay-background-position);
    background-size: var(--eaw-header-overlay-background-size);
    background-repeat: var(--eaw-header-overlay-background-repeat);
    content: "";
    height: var(--eaw-hero-row-height);
    opacity: var(--eaw-header-overlay-opacity);
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

.content--type-custom .component-row--is-root:first-child,
.content--type-news .component-row--is-root:first-child,
.content--type-page .component-row--is-root:first-child,
.content--type-template .component-row--is-root:first-child,
.content-page .row[data-id=layout-row0] {
  --eaw-widget-color: var(--eaw-content-first-row-color);
  --eaw-widget-heading-color: var(--eaw-content-first-row-color);
  --eaw-widget-font-size: #{map-get($font-sizes, '18px')};
  --eaw-widget-letter-spacing: 1px;
  --eaw-widget-line-height: #{map-get($font-sizes, '28px')};
  --eaw-heading1-font-size: #{map-get($font-sizes, '42px')};
  --eaw-heading1-line-height: #{map-get($font-sizes, '52px')};
  --eaw-heading2-font-size: #{map-get($font-sizes, '32px')};
  --eaw-heading2-line-height: #{map-get($font-sizes, '42px')};
  --eaw-heading3-font-size: #{map-get($font-sizes, '24px')};
  --eaw-heading3-line-height: #{map-get($font-sizes, '32px')};
  --eaw-heading4-font-size: #{map-get($font-sizes, '20px')};
  --eaw-heading4-line-height: #{map-get($font-sizes, '28px')};


  /*
  Hide the background image that might have been set using the out of the box lumapps tools.
  We use javascript to get the background image value and set it as a css property so it can be displayed properly.
  */
  background-image: none !important;
  background: var(--eaw-header-background) !important;
  background-position: var(--eaw-header-background-position) !important;
  background-size: var(--eaw-header-background-size) !important;
  background-repeat: var(--eaw-header-background-repeat) !important;
  // Needs to be important so the user can't override it using lumapps editor tools
  border: none !important;
  // Needs to be important so the user can't override it using lumapps editor tools
  border-radius: 0 !important;
  height: var(--eaw-hero-row-height);
  // Must be important since the user can attempt to set the margin in the page editing tools
  margin: 0 !important;

  // LumApps legacy design
  .component-widget {
    margin: 0;
  }

  .widget {
    margin: 0;
  }

  .widget-content {
    align-items: center;
    // Use a transparent border instead of padding, to make sure that the text is clipped correctly instead of flowing in to the padding
    border-bottom-width: var(--eaw-navbar-height);
    border-color: transparent;
    border-style: solid;
    display: flex;
    height: var(--eaw-hero-row-height);
    justify-content: center;
    padding: 0;
    overflow: hidden;
  }

  .widget-html {
    .block-html,
    .widget-html__content,
    .widget-html__wysiwyg .fr-view {
      text-align: center;
    }
  }

  // Set some styles for a better editing experience in our header row
  .widget-html--is-editing {
    .fr-box {
      background-color: #fff;
    }

    .widget-content {
      align-items: flex-start;
    }

    .widget-html__wysiwyg {
      border: 1px solid #ddd;
    }

    .widget-wrapper {
      width: 100%;

      .fr-toolbar.fr-sticky-on {
        position: absolute;
        top: 0 !important;
        width: 100% !important;
      }
    }
  }
}

// LumApps community content
.content--type-community {
  margin-top: calc(var(--eaw-hero-row-height) + var(--eaw-navbar-height) + 2rem);

  &:after {
    background: var(--eaw-header-overlay-background);
    background-position: var(--eaw-header-overlay-background-position);
    background-size: var(--eaw-header-overlay-background-size);
    background-repeat: var(--eaw-header-overlay-background-repeat);
    content: "";
    height: var(--eaw-hero-row-height);
    opacity: var(--eaw-header-overlay-opacity);
    pointer-events: none;
    position: absolute;
    top: var(--eaw-navbar-height);
    left: 0;
    right: 0;
  }

  &:before {
    background: var(--eaw-header-background) !important;
    background-position: var(--eaw-header-background-position) !important;
    background-size: var(--eaw-header-background-size) !important;
    background-repeat: var(--eaw-header-background-repeat) !important;
    content: "";
    height: var(--eaw-hero-row-height);
    pointer-events: none;
    position: absolute;
    top: var(--eaw-navbar-height);
    left: 0;
    right: 0;
  }

  .content-details {
    margin-top: 0 !important;
  }

  .community__new-post-btn-wrapper {
    bottom: 100px;
  }

  .community__new-post-btn {
    background-color: var(--eaw-link-color);
    color: #fff;
  }

  .component-cell--is-plain > .component-cell__components,
  .contribution-field,
  .post-block.post-block--view-mode-variant-ungroup {
    background-color: var(--eaw-body-bg-tertiary);
  }

  .widget-community-intro__content {
    color: var(--eaw-body-color);
  }

  .widget-title .widget-content .widget-title__title {
    font-size: var(--eaw-heading3-font-size);
    line-height: var(--eaw-heading3-line-height);
  }
}

// LumApps spaces content
.page[data-id="front-office-space-page-page"] {
  .space-header {
    margin-top: var(--eaw-navbar-height);

    &:after {
      background: var(--eaw-header-overlay-background);
      background-position: var(--eaw-header-overlay-background-position);
      background-size: var(--eaw-header-overlay-background-size);
      background-repeat: var(--eaw-header-overlay-background-repeat);
      content: "";
      height: var(--eaw-hero-row-height);
      opacity: var(--eaw-header-overlay-opacity);
      pointer-events: none;
      position: absolute;
      top: var(--eaw-navbar-height);
      left: 0;
      right: 0;
    }

    &:before {
      background: var(--eaw-header-background) !important;
      background-position: var(--eaw-header-background-position) !important;
      background-size: var(--eaw-header-background-size) !important;
      background-repeat: var(--eaw-header-background-repeat) !important;
      content: "";
      height: var(--eaw-hero-row-height);
      pointer-events: none;
      position: absolute;
      top: var(--eaw-navbar-height);
      left: 0;
      right: 0;
    }

    // Contains the space header content and about/follow buttons
    > .lumx-generic-block {
      --lumx-color-dark-L2: var(--eaw-content-first-row-color);

      color: var(--eaw-content-first-row-color);
      height: calc(var(--eaw-hero-row-height) - var(--eaw-navbar-height));
      margin-bottom: var(--eaw-navbar-height) !important;
      z-index: 1;

      .lumx-button--is-selected {
        background-color: rgb(255 255 255 / 25%);
      }
    }
  }
}
