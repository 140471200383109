:root {
  --eaw-card-bg: var(--eaw-body-bg);
  --eaw-card-border-color: var(--eab-gray-b-100);
  --eaw-card-border-width: 1px;
  --eaw-card-border-radius: 4px;
  --eaw-card-color: var(--eaw-body-color);
  --eaw-card-gray-bg: var(--eab-gray-d-100);
  --eaw-card-gray-border-color: var(--eab-gray-b-100);
  --eaw-card-padding-x: 2rem;
  --eaw-card-padding-y: 2rem;
  --eaw-card-padding-x-sm: 1.25rem;
  --eaw-card-padding-y-sm: 1.25rem;
  --eaw-card-shadow-color: rgb(0 0 0 / 20%);
  --eaw-card-shadow: 0 1px 3px var(--eaw-card-shadow-color);
  --eaw-card-cta-hover-shadow: 0 10px 20px 2px var(--eaw-card-shadow-color);
  --eaw-card-cta-hover-shift-y: -4px;
  --eaw-card-link-color: #{$link-color};
  --eaw-card-link-color-rgb: #{rgb-values-from-hex($link-color, '')};
  --eaw-card-link-color-hover: #{$link-color-hover};
}

@mixin base-card-style($isWidget: false) {
  --eaw-widget-bg: var(--eaw-card-bg);
  --eaw-widget-border-color: var(--eaw-card-border-color);
  --eaw-widget-border-width: var(--eaw-card-border-width);
  --eaw-widget-border-radius: var(--eaw-card-border-radius);
  --eaw-widget-color: var(--eaw-card-color);
  --eaw-widget-content-padding-top: var(--eaw-card-padding-top, var(--eaw-card-padding-y));
  --eaw-widget-content-padding-right: var(--eaw-card-padding-right, var(--eaw-card-padding-x));
  --eaw-widget-content-padding-bottom: var(--eaw-card-padding-bottom, var(--eaw-card-padding-y));
  --eaw-widget-content-padding-left: var(--eaw-card-padding-left, var(--eaw-card-padding-x));
  --eaw-widget-footer-link-font-size: #{map-get($font-sizes, '16px')};
  --eaw-widget-footer-line-height: #{map-get($font-sizes, '24px')};
  --eaw-widget-header-font-size: #{map-get($font-sizes, '24px')};
  --eaw-widget-header-font-weight: bold;
  --eaw-widget-header-line-height: #{map-get($font-sizes, '32px')};
  --eaw-widget-heading-color: var(--eaw-card-color);
  --eaw-widget-shadow: var(--eaw-card-shadow);

  $widgetSelector: '.widget';

  @if $isWidget {
    $widgetSelector: &;
    flex-grow: 1;
    transition: box-shadow 250ms ease-in-out, transform 250ms ease-in-out;

    @include media-breakpoint-up('lg') {
      + .widget {
        margin-top: 24px;
      }
    }

    &.widget--has-header .widget-content {
      --eaw-widget-content-padding-top: calc(var(--eaw-card-padding-y) / 2);

      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    &.widget--has-footer .widget-content {
      --eaw-widget-content-padding-bottom: calc(var(--eaw-card-padding-y) / 2);

      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  } @else {
    .component-cell__component {
      flex-grow: 1;

      @include media-breakpoint-up('lg') {
        + .component-cell__component {
          margin-top: 24px;
        }
      }
    }

    .widget {
      flex-grow: 1;
      transition: box-shadow 250ms ease-in-out, transform 250ms ease-in-out;

      @include media-breakpoint-up('lg') {
        + .widget {
          margin-top: 24px;
        }
      }
    }

    .widget.widget--has-header .widget-content {
      --eaw-widget-content-padding-top: calc(var(--eaw-card-padding-y) / 2);

      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .widget.widget--has-footer .widget-content {
      --eaw-widget-content-padding-bottom: calc(var(--eaw-card-padding-y) / 2);

      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .widget-footer,
  .widget-header {
    .widget-wrapper {
      border-color: unset;
      border-style: solid;
      border-width: 0;
      padding: calc(var(--eaw-card-padding-y) / 2) var(--eaw-card-padding-x);
    }
  }

  .widget-header {
    border-radius: var(--eaw-card-border-radius) var(--eaw-card-border-radius) 0 0;
  }

  .widget-footer {
    border-radius: 0 0 var(--eaw-card-border-radius) var(--eaw-card-border-radius);

    .widget-footer__link,
    .widget-wrapper {
      color: var(--eaw-card-link-color);

      &:focus,
      &:hover {
        color: var(--eaw-card-link-color-hover);
      }
    }
  }

  .widget-footer__label {
    font-size: 1rem;
  }

  .widget-content {
    border-radius: var(--eaw-card-border-radius);
    flex-grow: 1;

    a:not(.eaw-btn) {
      color: var(--eaw-card-link-color);

      &:focus,
      &:hover {
        color: var(--eaw-card-link-color-hover);
      }

      &.arrow {
        @include eaw-right-arrow();
      }
    }
  }
}

.component-row--cards,
.component-row--cards--icons,
.component-row--cards--image,
.component-row--cards--image-cta,
.component-row--cards-cta,
.component-row--cards-grey,
.component-row--cards-grey--icons,
.component-row--cards-tools,
.component-row--people-cards,
.component-row--people-cards--small,
.component-row--cards-teams {
  @include base-card-style();
}

.component-row--cards-cta,
.component-row--cards--icons,
.component-row--cards-grey--icons,
.component-row--cards-tools {
  .widget,
  .widget-content {
    background-position: var(--eaw-card-bg-position-x) var(--eaw-card-bg-position-y) !important;
    background-repeat: no-repeat;
    background-size: var(--eaw-card-icon-size) var(--eaw-card-icon-size) !important;
  }
}

.component-row--cards-cta,
.component-row--cards--image-cta,
.component-row--cards-tools {
  .widget {
    transition: box-shadow 250ms ease-in-out, transform 250ms ease-in-out;

    .app-content:not(.app-content--edit-mode) &,
    .layout & {
      &:hover {
        box-shadow: var(--eaw-card-cta-hover-shadow);
        transform: translate3d(0, var(--eaw-card-cta-hover-shift-y), 0);
      }
    }
  }

  .widget-html__content {
    .app-content:not(.app-content--edit-mode) & {
      position: initial;
    }
  }

  .widget-html .widget-content {
    .app-content:not(.app-content--edit-mode) &,
    .layout & {
      // Don't have the CTA box fully clickable when we are in edit mode as it would prevent the publisher from editing the html widget content
      a {
        // This is in order to make the CTA box completely clickable
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
        }
      }
    }

  }
}

.component-row--cards-cta {
  --eaw-card-bg-position-x: 50%;
  --eaw-card-bg-position-y: 24px;
  --eaw-card-icon-size: 76px;
  --eaw-card-padding-top: calc(var(--eaw-card-icon-size) + (calc(var(--eaw-card-bg-position-y) * 2)));

  .widget:not(.widget--has-header) .widget-content {
    padding-top: var(--eaw-card-padding-top) !important;
  }

  .widget.widget--has-header {
    padding-top: var(--eaw-card-padding-top) !important;
  }
}

.component-row--cards-grey,
.component-row--cards-grey--icons {
  --eaw-card-bg: var(--eaw-card-gray-bg);
  --eaw-card-border-color: var(--eaw-card-gray-border-color);
}

.component-row--cards--icons,
.component-row--cards-grey--icons,
.component-row--cards-tools {
  --eaw-card-bg-position-x: calc(var(--eaw-card-icon-size) / 2);
  --eaw-card-bg-position-y: 24px;
  --eaw-card-icon-size: 48px;
  --eaw-card-padding-left: calc(var(--eaw-card-icon-size) * 2);

  .widget:not(.widget--has-header):not(.widget--has-footer) .widget-content {
    padding-left: var(--eaw-card-padding-left) !important;
  }

  .widget.widget--has-header .widget-content,
  .widget.widget--has-header .widget-header .widget-wrapper,
  .widget.widget--has-footer .widget-content,
  .widget.widget--has-footer .widget-footer .widget-wrapper {
    padding-left: var(--eaw-card-padding-left) !important;
  }
}

.component-row--cards-cta,
.component-row--cards--image,
.component-row--cards--image-cta {
  .widget-content,
  .widget-header {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.component-row--cards--image,
.component-row--cards--image-cta {
  --eaw-card-image-size: 182px;
  --eaw-card-padding-y: 16px;

  .app-content--edit-mode & {
    .widget {
      background-position: top left !important;
      background-repeat: no-repeat;
      background-size: 100% var(--eaw-card-image-size) !important;

      &:after {
        background-color: rgba(0, 0, 0, .65);
        border-radius: var(--eaw-card-border-radius) var(--eaw-card-border-radius) 0 0;
        color: #fff;
        content: 'Note: This image may look stretched in edit mode, but should look fine in view mode.';
        font-size: map-get($font-sizes, '10px');
        left: 0;
        padding: 2px 5px;
        position: absolute;
        right: 0;
        text-align: center;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, .75);
        top: 0;
      }
    }

    .widget.widget--has-header {
      padding-top: var(--eaw-card-image-size);
    }

    .widget:not(.widget--has-header) .widget-content {
      --eaw-widget-content-padding-top: calc(var(--eaw-card-image-size) + var(--eaw-card-padding-y));

      background-position: top left !important;
      background-repeat: no-repeat;
      background-size: 100% var(--eaw-card-image-size) !important;
    }
  }

  .app-content:not(.app-content--edit-mode) &,
  .layout & {
    .widget {
      /*
      Hide the background image that might have been set using the out of the box lumapps tools.
      We use javascript to get the background image value and set it as a css property so it can be displayed properly.
      */
      background-image: none !important;
      padding-top: var(--eaw-card-image-size);
    }

    .widget-content {
      /*
      Hide the background image that might have been set using the out of the box lumapps tools.
      We use javascript to get the background image value and set it as a css property so it can be displayed properly.
      */
      background-image: none !important;

      &:before {
        background: var(--eaw-card-background-image, transparent);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: var(--eaw-card-border-radius) var(--eaw-card-border-radius) 0 0;
        content: '';
        height: var(--eaw-card-image-size);
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
}

.component-row--cards-tools {
  --eaw-card-bg-position-y: 16px;
  --eaw-card-padding-y: 12px;
  --eaw-card-padding-x: 24px;
  // --eaw-card-padding: 12px 24px;
  --eaw-headings-font-weight: normal;
  --eaw-heading4-font-size: #{map-get($font-sizes, '16px')};
  --eaw-heading4-line-height: #{map-get($font-sizes, '24px')};
  --eaw-widget-color: var(--eab-gray-c);
  --eaw-widget-font-size: #{map-get($font-sizes, '14px')};
  --eaw-widget-line-height: #{map-get($font-sizes, '20px')};
}

.content-details .widget.widget--card,
.content-details .widget.widget--card-cta,
.layout .widget.widget--card-cta,
.layout .widget.widget--card {
  @include base-card-style(true);
}

.widget.widget--card-cta {
  .app-content:not(.app-content--edit-mode) &,
  .layout & {
    &:hover {
      box-shadow: var(--eaw-card-cta-hover-shadow);
      transform: translate3d(0, var(--eaw-card-cta-hover-shift-y), 0);
    }
  }
}

@import 'cards-people';
