#sticky-page-navbar-spacer {
  display: none;
  margin-top: 0;
}

#sticky-page-navbar {
  --eaw-widget-max-width: 100%;

  background: var(--eaw-sticky-page-navbar-bg, var(--eaw-body-bg));
  box-shadow: var(--eaw-stick-page-navbar-shadow, 0px 4px 8px rgba(0, 0, 0, 0.15));
  margin-top: 0;

  &.sticky-page-navbar--sticky {
    box-shadow: 0 1px 5px rgba(0, 0, 0, .35);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;

    + #sticky-page-navbar-spacer {
      display: block;
    }
  }

  .content:not(.content--designer-mode) & {
    z-index: 1;
  }

  .component-widget {
    margin-bottom: 0;
  }

  .widget-html {
    .block-html,
    .widget-html__content,
    .widget-html__wysiwyg .fr-view {
      table {
        td, th {
          border: none;
        }
      }

      > div {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        a {
          color: var(--eaw-sticky-page-navbar-color, var(--eaw-link-color));
          font-size: var(--eaw-stick-page-navbar-font-size, #{map-get($font-sizes, '18px')});
          // font-weight: bold;
          line-height: var(--eaw-stick-page-navbar-line-height, #{map-get($font-sizes, '24px')});
          margin: 10px;
          text-align: center;
          width: 100%;

          &:hover {
            text-decoration: underline;
          }

          @include media-breakpoint-up('lg') {
            margin: 30px 10px;
            text-align: left;
            width: auto;
          }
        }
      }
    }
  }
}
