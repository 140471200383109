:root {
  --lumx-button-emphasis-low-state-active-theme-light-color: rgb(var(--eaw-widget-color-rgb) / 80%);
  --lumx-button-emphasis-low-state-active-theme-light-background-color: rgb(var(--eaw-widget-color-rgb) / 30%);
  --lumx-button-emphasis-low-state-default-theme-light-color: rgb(var(--eaw-widget-color-rgb) / 70%);
  --lumx-button-emphasis-low-state-hover-theme-light-color: rgb(var(--eaw-widget-color-rgb) / 70%);
  --lumx-button-emphasis-low-state-hover-theme-light-background-color: rgb(var(--eaw-widget-color-rgb) / 20%);
  --lumx-button-emphasis-high-state-default-theme-light-color: var(--eaw-widget-color-dark);
  --lumx-button-emphasis-high-state-default-theme-light-background-color: var(--eaw-widget-color);
  --lumx-button-emphasis-high-state-hover-theme-light-color: var(--eaw-widget-color);
  --lumx-button-emphasis-high-state-hover-theme-light-background-color: var(--eaw-widget-color-dark);
  --lumx-button-emphasis-medium-state-default-theme-light-color: var(--eaw-widget-color);
  --lumx-button-emphasis-medium-state-default-theme-light-background-color: var(--eaw-widget-color-dark);
  --lumx-button-emphasis-medium-state-hover-theme-light-color: var(--eaw-widget-color);
  --lumx-button-emphasis-medium-state-hover-theme-light-background-color: var(--eaw-widget-color-dark);
  --lumx-button-emphasis-selected-state-default-theme-light-color: var(--eaw-widget-color);
  --lumx-button-emphasis-selected-state-hover-theme-light-color: var(--eaw-widget-color);
  --lumx-color-secondary-N: rgb(var(--eaw-widget-color-rgb) / 75%);
  --lumx-color-dark-N: rgb(var(--eaw-widget-color-rgb) / 87%);
  --lumx-color-dark-L1: rgb(var(--eaw-widget-color-rgb) / 70%);
  --lumx-color-dark-L2: rgb(var(--eaw-widget-color-rgb) / 60%);
  --lumx-color-dark-L3: rgb(var(--eaw-widget-color-rgb) / 50%);
  --lumx-color-dark-L4: rgb(var(--eaw-widget-color-rgb) / 25%);
  --lumx-color-dark-L5: rgb(var(--eaw-widget-color-rgb) / 12%);
  --lumx-color-dark-L6: rgb(var(--eaw-widget-color-rgb) / 5%);
  --lumx-color-grey-N: rgb(var(--eaw-widget-color-rgb) / 65%);
  --lumx-text-field-state-default-theme-light-header-label-color: rgb(var(--eaw-widget-color-rgb) / 87%);
  --lumx-text-field-state-hover-theme-light-header-label-color: rgb(var(--eaw-widget-color-rgb) / 87%);
  --lumx-text-field-state-focus-theme-light-header-label-color: var(--eaw-widget-color);
  --lumx-text-field-state-default-theme-light-input-border-color: rgb(var(--eaw-widget-color-rgb) / 50%);
  --lumx-text-field-state-hover-theme-light-input-border-color: rgb(var(--eaw-widget-color-rgb) / 75%);
  --lumx-text-field-state-focus-theme-light-input-border-color: var(--eaw-widget-color);
  --lumx-text-field-state-default-theme-light-input-content-color: var(--eaw-widget-color);
  --lumx-text-field-state-hover-theme-light-input-content-color: var(--eaw-widget-color);
  --lumx-text-field-state-focus-theme-light-input-content-color: var(--eaw-widget-color);
}

.community-block--theme-light .community-block__content,
.posts-post-dialog .posts-post-dialog__title input,
.widget-standalone-question__title,
.widget--theme-light .widget-todo__unchecked-items .widget-todo-task__label,
.widget--theme-light .widget-todo__unchecked-items .widget-todo-task__label:before {
  color: var(--eaw-widget-color);
}

.content-block--default-style.content-block--theme-light .content-block-excerpt,
.directory-entry-block--theme-light .directory-entry-block-fields,
.folder-block--theme-light .folder-block__name,
.posts-post-dialog .posts-post-dialog__title input::placeholder,
.search-filter--theme-light .search-filter__input input::-webkit-input-placeholder,
.user-block--theme-light .user-block__secondary-field,
.widget--theme-light .widget-content__placeholder,
.widget--theme-light .widget-file-list-toolbar__wrapper span,
.widget--theme-light .widget-todo__checked-items .widget-todo-task__label,
.widget-todo-task__checkbox:checked+.widget-todo-task__label:after {
  color: rgb(var(--eaw-widget-color-rgb) / 75%);
}

.post-block.post-block--view-mode-list.post-block--view-mode-variant-group.post-block--theme-light,
.widget--l.widget--theme-light .widget-content-list__highlight-links,
.widget--theme-light .widget-content-list__highlight-link,
.widget--theme-light .widget-todo__checked-items--is-pushed {
  border-color: var(--eaw-border-color);
}

.lumx-button-wrapper--color-dark {
  background-color: rgb(var(--eaw-body-color-dark-rgb) / 80%);
}

.lumx-lightbox {
  background-color: var(--eaw-backdrop-bg);
}

.lumx-link {
  --lumx-color-dark-N: rgb(var(--eaw-link-color-rgb) / 75%);
  --lumx-color-primary-N: var(--eaw-link-color);

  &.lumx-link--color-secondary {
    --lumx-color-secondary-N: var(--eaw-link-color);
  }
}

.widget .rss-item .lumx-link:hover {
  color: var(--eaw-link-color-hover);
}

.widget--theme-light .widget-content-list__highlight-link.widget-content-list__highlight-link--is-active span,
.widget--theme-light .widget-content-list__highlight-link:hover span {
  --lumx-color-primary-N: var(--eaw-link-color);
}

.like-reaction-button--is-liked:not(.lumx-button--color-light) .lumx-icon,
.like-reaction-button:hover:not(.lumx-button--color-light) .lumx-icon {
  color: var(--eab-red-300);
}

.widget--theme-light .widget-breadcrumb__link:before {
  color: rgb(var(--eaw-widget-color-rgb) / 50%) !important;
}

.widget--theme-light .widget-content-list__highlight-link,
.widget--theme-light .widget-links__description,
.widget-standalone-question__participations {
  color: rgb(var(--eaw-widget-color-rgb) / 75%)
}

.directory-entry-block.directory-entry-block--view-mode-list.directory-entry-block--view-mode-variant-group.directory-entry-block--has-separator.directory-entry-block--theme-light:after,
.folder-block.folder-block--view-mode-list.folder-block--theme-light:after,
.media-block.media-block--view-mode-list.media-block--theme-light:after,
.user-block-wrapper.user-block-wrapper--theme-light:after,
.widget--theme-light .widget-file-list__toolbar:after,
.widget--theme-light .widget-links__list li:after {
  background-color: var(--eaw-border-color);
}

.widget-todo__new-task-add button:hover,
.widget-todo__new-task-add button[class*="--is-hovered"] {
  background-color: var(--eaw-body-bg-tertiary);
}

.lumx-notification {
  background-color: var(--eaw-notification-bg);
}

.lumx-button--color-dark:not(.lumx-button--is-selected).lumx-button--emphasis-medium,
.theme-lumapps .follow-button--view-mode-basic.follow-button--not-followed .follow-button__subscribe,
.theme-lumapps .follow-button--view-mode-extended.follow-button--not-followed .follow-button__subscribe,
.widget-contact__submit,
.widget--theme-light .widget-standalone-question__footer button,
.widget-todo__new-task-add button {
  background-color: var(--eab-gray-b-400);
  color: #fff;
  letter-spacing: 0.8px;

  &:hover,
  &[class*="--is-hovered"] {
    background-color: var(--eab-gray-a-400);
    color: #fff;
  }
}

.lumx-button {
  letter-spacing: 0.8px;
}

.lumx-button--color-primary.lumx-button--emphasis-high.lumx-button--theme-light {
  background-color: var(--eaw-link-color);
  color: #fff;

  &:hover {
    background-color: rgb(var(--eaw-link-color-rgb) / 80%);
    color: #fff;
  }
}

.lumx-radio-button--theme-light.lumx-radio-button--is-checked {
  .lumx-radio-button__input-background {
    color: var(--eaw-link-color);
  }

  .lumx-radio-button__input-indicator {
    background-color: var(--eaw-link-color);
  }
}

.lumx-checkbox--theme-light .lumx-checkbox__input-indicator {
  background-color: var(--eaw-link-color);
}

.lumx-checkbox--theme-light.lumx-checkbox--is-checked .lumx-checkbox__input-native:hover + .lumx-checkbox__input-placeholder .lumx-checkbox__input-indicator {
  background-color: rgb(var(--eaw-link-color-rgb) / 80%);
}

.front-office-error-page {
  margin-top: var(--eaw-navbar-height);
  padding-top: calc(var(--eaw-hero-row-height) + 2rem);

  &:before {
    background: linear-gradient(to right, var(--eab-blue-900) 0%, var(--eab-blue-700) 50%, var(--eab-blue-900) 100%);
    content: '';
    height: var(--eaw-hero-row-height);
    position: absolute;
    top: 0;
    left: -50%;
    right: -50%;
  }

  .lumx-base-state {
    background-color: var(--eaw-body-bg-tertiary);
    margin-left: auto;
    margin-right: auto;
    margin-top: initial;
    max-width: 600px;
    padding: 2rem;
  }

  .lumx-base-state__message {
    font-size: var(--eaw-heading4-font-size);
    line-height: var(--eaw-heading4-line-height);
  }

  .lumx-base-state__title {
    font-size: var(--eaw-heading2-font-size);
    line-height: var(--eaw-heading2-line-height);
    margin-bottom: 1rem;
  }
}

.lumx-date-picker__month-day--is-selected {
  background-color: rgb(var(--eaw-link-color-rgb) / 15%);
  color: var(--eaw-link-color);

  &:hover,
  &[class*="--is-hovered"] {
    background-color: rgb(var(--eaw-link-color-rgb) / 25%);
  }
}

.navigation-item__link {
  color: var(--eaw-link-color);
}

.navigation-item__link--is-selected {
  background-color: rgb(var(--eaw-link-color-rgb) / 10%);

  &:focus,
  &:has(.focus-visible),
  &:hover {
    background-color: rgb(var(--eaw-link-color-rgb) / 25%);

    .navigation-item__icon,
    .navigation-item__label {
      color: var(--eaw-link-color-hover);
    }
  }

  .navigation-item__icon,
  .navigation-item__label {
    color: var(--eaw-link-color);
  }
}



.no-results {
  color: rgb(var(--eaw-body-color-rgb) / 65%);
}

.admin__content {
  background-color: var(--eaw-body-bg);

  .admin-section-filter__search {
    background-color: var(--eaw-body-bg-tertiary);

    input {
      color: var(--eaw-body-color);

      &::placeholder {
        color: rgb(var(--eaw-body-color-rgb) / 50%);
      }
    }
  }

  .admin-section-title span {
    color: var(--eaw-body-color);
  }

  .lumx-table--theme-light .lumx-table__row--is-selected {
    background-color: rgb(var(--eaw-link-color-rgb) / 15%);

    &:hover {
      background-color: rgb(var(--eaw-link-color-rgb) / 25%);
    }
  }

  .lumx-chip--is-unselected.lumx-chip--color-green {
    background-color: rgb(map-get($alert-colors, 'success'), 25%);
    color: map-get($alert-colors, 'success');
  }
}

.backoffice-navigation {
  background-color: var(--eaw-body-bg-tertiary);

  .navigation-header {
    background-color: var(--eaw-body-bg-secondary);
  }

  .admin-nav__link-wrapper,
  .admin-nav__subheader,
  .side-navigation-section {
    background-color: transparent;
  }

  .side-navigation-section__header {
    color: rgb(var(--eaw-body-color-rgb) / 75%);
  }

  .lumx-side-navigation-item--is-selected .lumx-side-navigation-item__link {
    background-color: rgb(var(--eaw-link-color-rgb) / 15%);
    color: var(--eaw-link-color);
  }
}

.lumx-dialog {
  .lumx-tabs--theme-light .lumx-tabs__link--is-active:after {
    border-color: var(--eaw-link-color);
  }

  .search-filter--theme-light .search-filter__input input {
    color: var(--eaw-body-color);
  }
}

.component-settings__available-widget {
  border-color: rgb(var(--eaw-body-color-rgb) / 20%);

  span,
  strong {
    color: var(--eaw-body-color);
  }
}

.component-settings__available-widget--is-active,
.component-settings__available-widget:not(.component-settings__available-widget--is-disabled):hover {
  background-color: var(--eaw-body-bg-tertiary);
}

.lumx-slideshow-controls__pagination-items,
.widget-slideshow__pager {
  column-gap: 8px;
}

.lumx-slideshow-controls--theme-light .lumx-slideshow-controls__pagination-item,
.widget-slideshow__pager-bullet {
  background-color: rgb(var(--eaw-body-color-rgb) / 50%);
  color: rgb(var(--eaw-body-color-rgb) / 50%);
  margin: 0;

  &:hover {
    background-color: var(--eaw-link-color-hover);
    color: var(--eaw-link-color-hover);
  }
}

.lumx-slideshow-controls--theme-light .lumx-slideshow-controls__pagination-item--is-active,
.widget-slideshow__pager-bullet--is-active {
  background-color: var(--eaw-body-color);
  color: var(--eaw-body-color);
}

.space-wrapper {
  .media-library__file-list {
    background-color: var(--eaw-body-bg-tertiary);
  }

  .block-list--ungrouped > .block-list__item,
  .space-articles__article-list .block-augmented-container,
  .space-articles__article-list .block-augmented-container--is-loading,
  .space-events__event-list .block-augmented-container,
  .space-events__event-list .block-augmented-container--is-loading,
  .space-contribution-field {
    background-color: var(--eaw-body-bg-tertiary) !important;
  }

  .widget-community-post-list {
    .block-list__item,
    .block-no-results,
    .space-contribution-field {
      background-color: var(--eaw-body-bg-tertiary) !important;
      color: var(--eaw-body-color);
    }
  }
}

.lumx-color-background-light-N {
  background-color: var(--eaw-body-bg-tertiary);
}

.page {
  .block-article-preview--theme-light .block-article-preview__description,
  .block-event-preview--theme-light .block-event-preview__description,
  .generic-entity-block__additional-info,
  .generic-entity-block__before-additional-info,
  .generic-entity-block__description,
  .media-library__owner {
    color: var(--eaw-body-color-tertiary);
  }
}

// .theme-lumapps .widget--theme-light .widget-breadcrumb__link {
//   &:last-child {
//     span {
//       color: var(--eaw-link-color);
//     }
//   }

//   span {
//     color: var(--eaw-body-color);
//   }
// }
