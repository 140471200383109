.component-row--kb-faqs {
  --eaw-widget-max-width: auto;

  .cell__wrapper,
  .component-cell__components {
    padding: 0;
  }

  .component-cell,
  .cell__wrapper {
    border-radius: 4px;
    box-shadow: 0px 8px 16px rgb(var(--eaw-body-color-rgb) / 10%);
  }

  .widget {
    --eaw-widget-border-bottom-color: var(--eaw-body-bg-secondary);
    --eaw-widget-border-bottom-width: 1px;

    &:last-child {
      --eaw-widget-border-bottom: none;
    }
  }

  .widget-header {
    --eaw-widget-header-font-weight: bold;
    --eaw-widget-header-letter-spacing: 0.8px;

    background-color: var(--eaw-body-bg-secondary);
    transition: 250ms ease background;

    .widget-wrapper {
      border-bottom: none;
      padding: 24px 64px 24px 16px;
      position: relative;

      &:after {
        @include fa-icon-light('chevron-up', 'absolute');
        font-size: 1.5rem;
        right: 18px;
        transform-origin: center 22.5%;
        transition: 250ms ease transform;
      }
    }
  }

  .widget--is-folded {
    .widget-header .widget-wrapper {
      background-color: var(--eaw-body-bg);

      &:after {
        transform: rotate(-180deg);
      }
    }

    .widget-footer {
      display: none !important;
    }
  }

  #{$widget-html-content-selector} {
    padding: 24px 16px;
  }

  // .widget-html {
  //   border-radius: 4px;

  //   &.widget--is-folded {
  //     .widget-header .widget-wrapper {
  //       background-color: #FFF;

  //       &:after {
  //         transform: rotate(0);
  //       }
  //     }

  //     .widget-footer {
  //       display: none !important;
  //     }
  //   }

  //   .widget-header .widget-wrapper {
  //     background-color: #f2f2f2;
  //     border: none;
  //     padding: 24px 64px 24px 16px;
  //     position: relative;

  //     &:after {
  //       background-image: resolve('images/faq-chevron.svg');
  //       background-repeat: no-repeat;
  //       content: "";
  //       height: 24px;
  //       position: absolute;
  //       right: 18px;
  //       transform: rotate(-180deg);
  //       transition: 250ms ease transform;
  //       width: 24px;
  //     }
  //   }

  //   .fr-view,
  //   .widget-html__content {
  //     padding: 24px 16px;

  //     a {
  //       color: #0000ee;

  //       &:active {
  //         color: #080080;
  //       }

  //       &:hover {
  //         color: #291aff;
  //       }
  //     }

  //     p {
  //       color: #262626;
  //       // @include metaui-typography-body();
  //     }

  //     .btn {
  //       @include nova-ui-button();
  //     }
  //   }

  //   .widget-header__title {
  //     // @include metaui-typography-label-nav();
  //     color: #262626;
  //   }

  //   .widget-footer {
  //     margin-top: 10px;
  //     padding: 0 16px;

  //     .widget-wrapper {
  //       border: 1px solid #F2F2F2;
  //       border-width: 1px 0 0 0;
  //     }
  //   }

  //   .widget-footer__link {
  //     @include nova-ui-button();
  //   }
  // }
}
