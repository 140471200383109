.component-row--help-us-improve {
  .widget-contact {
    opacity: 0;
  }

  .content--designer-mode &,
  &.component-row--help-us-improve--active {
    .widget-contact {
      display: flex;
      opacity: 1;
      transition: opacity 0.5s linear;
    }

    #{$widget-html-content-selector} {
      p {
        a {
          &:after {
            @include fa-icon-light('angle-up', 'inline-block');
          }
        }
      }
    }
  }

  .component-row__wrapper,
  .row-wrapper {
    border-top: dashed 2px rgb(var(--eaw-body-color-rgb) / 25%);
    padding-top: 64px !important;
  }

  .widget-contact {
    display: none;
  }

  #{$widget-html-content-selector} {
    text-align: center;

    h3 {
      margin-bottom: 16px;
    }

    p {
      a {
        display: inline-block;
        text-decoration: none;

        &:after {
          @include fa-icon-light('angle-down', 'inline-block');
          margin-left: 8px;
        }
      }
    }
  }
}
