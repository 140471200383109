.component-cell--bg-gradient,
.component-row--bg-gradient,
.widget.widget--bg-gradient {
  --eaw-link-color: #ffeb3b; // TODO: Replace later with one of the theme colors?
  --eaw-link-color-hover: #ffeb3b; // TODO: Replace later with one of the theme colors?
  --eaw-widget-color: var(--eaw-widget-color-themed, #{$widget-color-themed});
  --eaw-widget-header-color: var(--eaw-widget-color-themed, #{$widget-color-themed});

  background: var(--eaw-theme-color-start, #{$theme-color-start});
  background-image: none;
  background: linear-gradient(to right, var(--eaw-theme-color-start, #{$theme-color-start}) 0%, var(--eaw-theme-color-middle, #{$theme-color-middle}) 50%, var(--eaw-theme-color-end, #{$theme-color-end}) 100%);

  #{$widget-html-content-selector} {
    a:hover {
      text-decoration: underline;
    }

    > table tbody {
      color: var(--eaw-body-color);
    }
  }

  &.component-row--quick-links {
    #{$widget-html-content-selector} {
      p {
        a {
          border-bottom-color: var(--eaw-widget-quick-links-border-color-themed, #fff);
          color: var(--eaw-widget-color, #fff);
          opacity: .8;

          &:hover {
            border-bottom-color: var(--eaw-widget-color);
            opacity: 1;
            text-decoration: none;

            &:after {
              color: var(--eaw-widget-color);
            }
          }

          &:after {
            color: var(--eaw-widget-color, #fff);
          }
        }
      }
    }
  }
  }
