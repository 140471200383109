@use "sass:list";
@use "sass:meta";

$all-brand-image-classes: ();
$all-dark-text-classes: ();
$all-dark-link-classes: ();
$all-light-text-classes: ();
$all-light-link-classes: ();
$element-prefixes: 'component-row', 'component-cell', 'widget';
$ea-brand-gradients: (
  "green": (
    "dark": (
      "color": rgba(6, 53, 5, 1),
      "location": 3.2798%
    ),
    "light": (
      "color": rgba(87, 214, 10, 1),
      "location": 100%
    )
  ),
  "blue": (
    "dark": (
      "color": rgba(2, 13, 69, 1),
      "location": 5.366%
    ),
    "light": (
      "color": rgba(4, 77, 234, 1),
      "location": 100%
    )
  ),
  "teal": (
    "dark": (
      "color": rgba(4, 33, 70, 1),
      "location": 6.0557%
    ),
    "light": (
      "color":rgba(6, 191, 209, 1),
      "location": 90.5473%
    )
  ),
  "red": (
    "dark": (
      "color": rgba(93, 5, 3, 1),
      "location": 3.7093%
    ),
    "light": (
      "color": rgba(231, 5, 9, 1),
      "location": 100%
    )
  ),
  "orange": (
    "dark": (
      "color": rgba(150, 6, 4, 1),
      "location": 1.2364%
    ),
    "light": (
      "color": rgba(251, 104, 21, 1),
      "location": 100%
    )
  ),
  "yellow": (
    "dark": (
      "color": rgba(213, 59, 6, 1),
      "location": 4.0297%
    ),
    "light": (
      "color": rgba(252, 198, 1, 1),
      "location": 98.4753%
    )
  ),
  "pink": (
    "dark": (
      "color": rgba(58, 3, 37, 1),
      "location": 3.0315%
    ),
    "light": (
      "color": rgba(231, 2, 139, 1),
      "location": 99.2952%
    )
  ),
  "purple": (
    "dark": (
      "color": rgba(18, 2, 79, 1),
      "location": 6.157%
    ),
    "light": (
      "color": rgba(109, 2, 229, 1),
      "location": 100%
    )
  ),
  "violet": (
    "dark": (
      "color": rgba(28, 2, 50, 1),
      "location": 4.0261%
    ),
    "light": (
      "color": rgba(187, 3, 231, 1),
      "location": 99.4846%
    )
  )
);
$ea-brand-images: (
  "marbled": (
    "link-color-theme": "light",
    "text-color-theme": "light"
  ),
  "blue-green": (
    "prefix": "primary"
  ),
  "purple-pink": (
    "prefix": "primary"
  ),
  "red-yellow": (
    "prefix": "primary"
    ),
  "blue-indigo": (
    "prefix": "secondary",
    "link-color-theme": "light",
    "text-color-theme": "light"
  ),
  "burnt-orange": (
    "prefix": "secondary"
  ),
  "green-emerald": (
    "prefix": "secondary"
  ),
  "pink-rose": (
    "prefix": "secondary",
    "link-color-theme": "light",
    "text-color-theme": "light"
  ),
  "purple-plum": (
    "prefix": "secondary",
    "link-color-theme": "light",
    "text-color-theme": "light"
  ),
  "red-maroon": (
    "prefix": "secondary",
    "link-color-theme": "light",
    "text-color-theme": "light"
  ),
  "teal-agave": (
    "prefix": "secondary",
    "link-color-theme": "light",
    "text-color-theme": "light"
  ),
  "violet-burgundy": (
    "prefix": "secondary",
    "link-color-theme": "light",
    "text-color-theme": "light"
  ),
  "yellow-mustard": (
    "prefix": "secondary"
  ),
);
$ea-brand-image-sizes: (
  "": "600",
  "-sm": "300",
  "-lg": "900"
);

@function print-ratio-emoji($calculated-ratio, $expected-ratio) {
  @if $calculated-ratio > $expected-ratio {
    @return "✅";
  } @else {
    @return "❌";
  }
}

@function print-contrast-ratios($ratios) {
  $output: ();

  @each $ratio in $ratios {
    $output: list.append($output, "<td>#{$ratio} #{print-ratio-emoji($ratio, $min-contrast-ratio)}<div><header>WCAG 2.0</header><div>3: #{print-ratio-emoji($ratio, 3)}</div><div>4.5: #{print-ratio-emoji($ratio, 4.5)}</div><div>7: #{print-ratio-emoji($ratio, 7)}</div></div></td>");
  }

  @return $output;
  // @return "#{$contrast-ratio}<br>3: #{print-ratio-emoji($contrast-ratio, 3)}<br>4.5: #{print-ratio-emoji($contrast-ratio, 4.5)}<br>7: #{print-ratio-emoji($contrast-ratio, 7)}";
}

// @each $color, $value in $ea-brand-colors {
// @each $color, $value in map-sort($ea-brand-colors) {
  // @debug "<li><strong>#{$color}</strong> (hex: #{$value} <span style='background-color: #{$value}; display: inline-block; width: 20px'>&nbsp;</span>): Class name: <code>eab-#{$color}</code></li>";
  // @debug "<option>#{$color}</option>";
// }

// @each $color, $value in map-sort($ea-brand-config) {
//   @if type-of($value) != 'map' {
//     @debug "Brand color '#{$color}' IS NOT a map";
//   } @else {
//     @debug "Brand color '#{$color}' IS a map";
//   }
// }

// @debug "Contrast Text Colors: widget-color-light: #{$widget-color-light}; widget-color-dark: #{$widget-color-dark}; white: #{$white}; black: #{$black};";
@each $color, $value in map-sort($ea-brand-colors) {
  $contrast-ratio-light: contrast-ratio($value, $widget-color-light);
  $contrast-ratio-dark: contrast-ratio($value, $widget-color-dark);
  $contrast-ratio-link-light: contrast-ratio($value, $link-color-light);
  $contrast-ratio-link-dark: contrast-ratio($value, $link-color-dark);
  $contrast-ratio-white: contrast-ratio($value, $white);
  $contrast-ratio-black: contrast-ratio($value, $black);
  $contrast-ratio-error: contrast-ratio($value, $widget-error-color);
  $contrast-ratio-error-alt: contrast-ratio($value, $widget-error-color-alt);
  $contrast-ratios: ($contrast-ratio-light, $contrast-ratio-dark, $contrast-ratio-link-light, $contrast-ratio-link-dark, $contrast-ratio-white, $contrast-ratio-black, $contrast-ratio-error, $contrast-ratio-error-alt);
  // @debug "<tr><th>#{$color}<br />#{$value} <span class='brand-color-example' style='background-color: #{$value};'>&nbsp;</span></th>#{print-contrast-ratios($contrast-ratios)}</tr>";
  // @debug "* **#{$color}** ~ * active-border: #{$contrast-ratio-active-border}; ~ * active-border-alternate: #{$contrast-ratio-active-border-alternate}; ~ * focused-border: #{$contrast-ratio-focused-border}; ~ * focused-border-alternate: #{$contrast-ratio-focused-border-alternate};";

  $classes: (unquote(".eab-#{$color}"));

  @each $prefix in $element-prefixes {
    $classes: append($classes, unquote(".#{$prefix}--eab-#{$color}"), comma);
  }

  // @debug $classes;

  @if $contrast-ratio-light > $min-contrast-ratio or $contrast-ratio-dark < $contrast-ratio-light {
    $all-light-text-classes: list.join($all-light-text-classes, $classes, comma);
  } @else {
    $all-dark-text-classes: list.join($all-dark-text-classes, $classes, comma);
  }

  @if $contrast-ratio-link-light > $min-contrast-ratio or $contrast-ratio-link-dark < $contrast-ratio-link-light {
    $all-light-link-classes: list.join($all-light-link-classes, $classes, comma);
  } @else {
    $all-dark-link-classes: list.join($all-dark-link-classes, $classes, comma);
  }

  #{$classes} {
    --eaw-background-active-color: var(--eab-#{$color});
    --eaw-widget-bg: var(--eab-#{$color});
    --eaw-widget-breadcrumb-color: var(--eaw-link-color);
    --eaw-widget-table-alt-bg: rgb(220 220 220 / 65%);
    --eaw-widget-table-head-bg: rgb(39 112 194 / 50%);
    --eaw-widget-table-border-color: #fff;
    --eaw-widget-table-head-color: #{$widget-table-head-color};

    @if $contrast-ratio-error > $min-contrast-ratio or $contrast-ratio-error-alt < $contrast-ratio-error {
      --eaw-widget-error-color: #{$widget-error-color};
    } @else {
      --eaw-widget-error-color: #{$widget-error-color-alt};
    }
    background-color: var(--eab-#{$color}, $value);
  }
}

@each $gradient-name, $gradient-details in map-sort($ea-brand-gradients) {
  $background-color: map-get($gradient-details, "light", "color");
  $contrast-ratio-light: contrast-ratio($background-color, $widget-color-light);
  $contrast-ratio-dark: contrast-ratio($background-color, $widget-color-dark);
  $contrast-ratio-link-light: contrast-ratio($background-color, $link-color-light);
  $contrast-ratio-link-dark: contrast-ratio($background-color, $link-color-dark);
  $gradient: linear-gradient(0deg, map-get($gradient-details, "dark", "color") map-get($gradient-details, "dark", "location"), map-get($gradient-details, "light", "color") map-get($gradient-details, "light", "location"));

  $classes: (unquote(".eab-gradient-#{$gradient-name}"));
  $classes-reverse: (unquote(".eab-gradient-#{$gradient-name}-reversed"));
  $classes-left: (unquote(".eab-gradient-#{$gradient-name}-left"));
  $classes-right: (unquote(".eab-gradient-#{$gradient-name}-right"));

  // @debug "<option value=\"gradient-#{$gradient-name}\">#{$gradient-name}</option>";
  // @debug "<option value=\"gradient-#{$gradient-name}-reversed\">#{$gradient-name} - Reversed</option>";
  // @debug "<option value=\"gradient-#{$gradient-name}-left\">#{$gradient-name} - Left</option>";
  // @debug "<option value=\"gradient-#{$gradient-name}-right\">#{$gradient-name} - Right</option>";

  @each $prefix in $element-prefixes {
    $classes: append($classes, unquote(".#{$prefix}--eab-gradient-#{$gradient-name}"), comma);
    $classes-reverse: append($classes-reverse, unquote(".#{$prefix}--eab-gradient-#{$gradient-name}-reversed"), comma);
    $classes-left: append($classes-left, unquote(".#{$prefix}--eab-gradient-#{$gradient-name}-left"), comma);
    $classes-right: append($classes-right, unquote(".#{$prefix}--eab-gradient-#{$gradient-name}-right"), comma);
  }

  @if $contrast-ratio-light > $min-contrast-ratio or $contrast-ratio-dark < $contrast-ratio-light {
    $all-light-text-classes: list.join($all-light-text-classes, $classes, comma);
    $all-light-text-classes: list.join($all-light-text-classes, $classes-reverse, comma);
    $all-light-text-classes: list.join($all-light-text-classes, $classes-left, comma);
    $all-light-text-classes: list.join($all-light-text-classes, $classes-right, comma);
  } @else {
    $all-dark-text-classes: list.join($all-dark-text-classes, $classes, comma);
    $all-dark-text-classes: list.join($all-dark-text-classes, $classes-reverse, comma);
    $all-dark-text-classes: list.join($all-dark-text-classes, $classes-left, comma);
    $all-dark-text-classes: list.join($all-dark-text-classes, $classes-right, comma);
  }

  @if $contrast-ratio-link-light > $min-contrast-ratio or $contrast-ratio-link-dark < $contrast-ratio-link-light {
    $all-light-link-classes: list.join($all-light-link-classes, $classes, comma);
    $all-light-link-classes: list.join($all-light-link-classes, $classes-reverse, comma);
    $all-light-link-classes: list.join($all-light-link-classes, $classes-left, comma);
    $all-light-link-classes: list.join($all-light-link-classes, $classes-right, comma);
  } @else {
    $all-dark-link-classes: list.join($all-dark-link-classes, $classes, comma);
    $all-dark-link-classes: list.join($all-dark-link-classes, $classes-reverse, comma);
    $all-dark-link-classes: list.join($all-dark-link-classes, $classes-left, comma);
    $all-dark-link-classes: list.join($all-dark-link-classes, $classes-right, comma);
  }

  #{$classes},
  #{$classes-reverse},
  #{$classes-left},
  #{$classes-right} {
    background-color: $background-color;
  }

  #{$classes} {
    background-image: $gradient;
  }

  #{$classes-reverse} {
    background-image: str-replace($gradient, "0deg", "180deg");
  }

  #{$classes-left} {
    background-image: str-replace($gradient, "0deg", "270deg");
  }

  #{$classes-right} {
    background-image: str-replace($gradient, "0deg", "90deg");
  }
}

@each $image-name, $image-details in $ea-brand-images {
  $base-class-name: "#{$image-name}";
  $base-image-path: "images/ea-brand/#{$image-name}";
  $image-prefix: map-get($image-details, "prefix");
  $link-color-theme: map-get($image-details, "link-color-theme");
  $text-color-theme: map-get($image-details, "text-color-theme");

  @if $image-prefix {
    $base-class-name: "#{$image-prefix}-#{$image-name}";
    $base-image-path: "images/ea-brand/#{$image-prefix}/#{$image-name}";
  }

  @each $version in "v1", "v2", "v3" {
    @each $size-name, $size in $ea-brand-image-sizes {
      $classes: (unquote(".eab-#{$base-class-name}-#{$version}#{$size-name}"));

      // @debug "<option value=\"eab-#{$base-class-name}-#{$version}#{$size-name}\">#{$image-name} #{$version} #{$size-name}</option>";

      @each $prefix in $element-prefixes {
        $classes: append($classes, unquote(".#{$prefix}--eab-#{$base-class-name}-#{$version}#{$size-name}"), comma);
        $all-brand-image-classes: append($all-brand-image-classes, unquote(".#{$prefix}--eab-#{$base-class-name}-#{$version}#{$size-name}"), comma);
      }

      @if $link-color-theme == "light" {
        $all-light-link-classes: list.join($all-light-link-classes, $classes, comma);
      }

      @if $text-color-theme == "light" {
        $all-light-text-classes: list.join($all-light-text-classes, $classes, comma);
      }

      #{$classes} {
        background-image: resolve("#{$base-image-path}/1920x#{$size}-#{$image-name}-#{$version}.jpg");
      }
    }
  }
}

#{$all-brand-image-classes} {
  background-repeat: no-repeat;
  background-size: cover;
}

#{$all-light-link-classes} {
  --eaw-link-color: var(--eaw-link-color-light, #{$link-color-light});
  --eaw-link-color-hover: var(--eaw-link-color-light-hover, #{$link-color-light-hover});
}

#{$all-light-text-classes} {
  --eaw-button-bg: var(--eaw-widget-color-light, #{$widget-color-light});
  --eaw-button-bg-rgb: var(--eaw-widget-color-light-rgb, #{$widget-color-light-rgb});
  --eaw-button-color: var(--eaw-widget-color-dark, #{$widget-color-dark});
  --eaw-widget-breadcrumb-color: var(--eaw-widget-color-light, #{$widget-color-light});
  --eaw-widget-color: var(--eaw-widget-color-light, #{$widget-color-light});
  --eaw-widget-color-rgb: var(--eaw-widget-color-light-rgb, #{$widget-color-light-rgb});
  --eaw-widget-heading-color: var(--eaw-widget-color-light, #{$widget-color-light});
}

#{$all-dark-link-classes} {
  --eaw-link-color: var(--eaw-link-color-dark, #{$link-color-dark});
  --eaw-link-color-hover: var(--eaw-link-color-dark-hover, #{$link-color-dark-hover});
}

#{$all-dark-text-classes} {
  --eaw-button-bg: var(--eaw-widget-color-dark, #{$widget-color-dark});
  --eaw-button-bg-rgb: var(--eaw-widget-color-dark-rgb, #{$widget-color-dark-rgb});
  --eaw-button-color: var(--eaw-widget-color-light, #{$widget-color-light});
  --eaw-widget-breadcrumb-color: var(--eaw-widget-color-dark, #{$widget-color-dark});
  --eaw-widget-color: var(--eaw-widget-color-dark, #{$widget-color-dark});
  --eaw-widget-color-rgb: var(--eaw-widget-color-dark-rgb, #{$widget-color-dark-rgb});
  --eaw-widget-heading-color: var(--eaw-widget-color-dark, #{$widget-color-dark});
}
