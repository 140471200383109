

.component-row--people-cards {
  .widget-html .widget-content {
    p {
      font-size: map-get($font-sizes, '14px');
      line-height: map-get($font-sizes, '20px');

      // The first child should be the paragraph with the image in it.
      &:first-child {
        margin-bottom: 16px;

        img {
          border: 2px solid var(--eab-gray-e);
          border-radius: 50%;
          display: block;
          height: 80px !important;
          margin: 0 auto;
          width: 80px !important;
        }
      }

      // The last child should be the paragraph with a link in it.
      &:last-child {
        text-align: center;

        a {
          @include eaw-right-arrow();
        }
      }

      // The second paragraph should be the persons name
      // The third should be the business title
      // The fourth should be the email
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        text-align: center;
      }

      &:nth-child(2),
      &:nth-child(3) {
        margin-bottom: 4px;
      }

      &:nth-child(2) {
        font-size: var(--eaw-heading5-font-size);
        font-weight: bold;
        letter-spacing: --eaw-headings-letter-spacing;
        line-height: var(--eaw-heading5-line-height);
      }
    }
  }
}

.component-row--people-cards--small {
  .widget-html .widget-content {
    --eaw-widget-content-padding-top: 16px;
    --eaw-widget-content-padding-right: 20px;
    --eaw-widget-content-padding-bottom: 16px;
    --eaw-widget-content-padding-left: 100px;

    position: relative;

    p {
      font-size: map-get($font-sizes, '14px');
      line-height: map-get($font-sizes, '20px');

      // The first paragraph should be the one with the image in it
      &:first-child {
        left: 20px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        img {
          border: 2px solid var(--eab-gray-e);
          border-radius: 50%;
          display: block;
          height: 64px !important;
          margin: 0 auto;
          width: 64px !important;
        }
      }

      // The second paragraph should be the persons name
      &:nth-child(2) {
        font-size: var(--eaw-heading5-font-size);
        font-weight: bold;
        letter-spacing: --eaw-headings-letter-spacing;
        line-height: var(--eaw-heading5-line-height);
        margin-bottom: 8px;
      }

      // The third should be the business title
      &:nth-child(3) {
        margin-bottom: 0;
      }

      // The fourth should be the business unit
      &:nth-child(4) {
        margin-bottom: 16px;
      }

      // The fifth should be the social icons
      &:nth-child(5) {
        .fab {
          vertical-align: initial;
        }

        a {
          color: var(--eab-gray-c);
          display: inline-block;
          font-size: map-get($font-sizes, '18px');
          margin-right: 12px;

          // Slack icon
          &:nth-child(1) {
            &:hover {
              color: #333a42;
            }
          }

          // Skype icon
          &:nth-child(2) {
            &:hover {
              color: #0C9ED9;
            }
          }

          // Email icon
          &:nth-child(3) {
            &:hover {
              color: #0052CC;
            }
          }

          // Phone icon
          &:nth-child(4) {
            font-size: map-get($font-sizes, '16px');
            vertical-align: top;

            // The FA phone icon is displayed from bottom left to top right, but we want it from top left to bottom right, so we have to flip it.
            .fa-phone {
              transform: scaleX(-1);
            }

            &:hover {
              color: #049344;
            }
          }
        }
      }
    }
  }

  .widget-html .widget-html__content {
    p {
      &:first-child {
        left: -80px;
      }
    }
  }
}
